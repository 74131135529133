import '../../common/status-icons.css';
import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  ButtonGroup,
  FormControl,
  InputLabel,
  LinearProgress,
  MenuItem,
  Paper,
  Select,
  Snackbar,
  TextField,
} from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import ShowIf from '../../components/ShowIf';
import ColoredLine from '../../components/ColoredLine';
import ExecutionsTable from './ExecutionsTable';
import { makeStyles } from '@material-ui/core/styles';
import { DateRangePicker } from 'materialui-daterange-picker';
import Dialog from '@material-ui/core/Dialog';
import ExecutionApi, { ExecutionsFilter, Sort, SORT_FIELDS } from '../../services/ExecutionApi';
import { ExecutionStatus, LogExecution } from '../../services/LogExecution';
import moment from 'moment';
import TaskApi, { TaskType } from '../../services/TaskApi';
import { Query, QueryResult } from 'material-table';
import { useLocation } from 'react-router-dom';

const useStyles = makeStyles({
  searchFormContainer: {
    display: 'flex',
    alignItems: 'baseline',
    marginBottom: '30px',
  },
  dateTabsContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: '30px',
  },
  dateTabs: {
    width: '30%',
  },
  datePickerDialogButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '10px',
  },
  searchInput: {
    width: '200px',
    height: '40px',
    marginRight: '40px',
  },
  searchInputLarge: {
    width: '400px',
    height: '40px',
    marginRight: '40px',
  },
  searchButton: {
    width: '150px',
    height: '30px',
    marginLeft: 'auto',
  },
  formControl: {
    marginRight: '40px',
    minWidth: '150px',
  },
});

const monthAgo = new Date();
monthAgo.setMonth(new Date().getMonth() - 1);

const defaultDates = {
  from: monthAgo.toISOString(),
};
export default () => {
  const [openSnack, setOpenSnack] = React.useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [dateFilterOption, setDateFilterOption] = React.useState(1);
  const [dateRange, setDateRange] = React.useState<DateRange>({});
  const [datePickerOpen, setDatePickerOpen] = React.useState<boolean>(false);
  const [taskTypes, setTaskTypes] = React.useState<TaskType[]>([]);
  const search = useLocation().search;
  const searchParams = new URLSearchParams(search);
  const dateParam = searchParams.get('date');
  const statusParam = searchParams.get('status') as ExecutionStatus;
  const typeParam = searchParams.get('type') || undefined;

  const [searchRequest, setSearchRequest] = useState<ExecutionsFilter>({
    dates: defaultDates,
    status: [ExecutionStatus.CRITICAL, ExecutionStatus.ERROR],
    sortBy: { property: 'id', order: 'desc' },
  });

  const attToColumn = new Map<string, SORT_FIELDS>([
    ['id', 'id'],
    ['startedAt', 'started_at'],
    ['externalRef', 'external_ref'],
  ]);
  const classes = useStyles();

  const tableRef: any = React.createRef();

  useEffect(() => {
    setIsLoading(true);

    if (dateParam) {
      const from = moment(new Date()).utc().startOf('day');
      const [month, day, year]: any = dateParam.split('-');
      from.set('month', month - 1);
      from.set('date', day);
      from.set('year', year);
      const to = moment(from).endOf('day');
      setSearchRequest({
        dates: { from: from.toISOString(), to: to.toISOString() },
        status: [statusParam],
        taskType: typeParam,
        sortBy: { property: 'id', order: 'desc' },
      });
      setDateRange({ startDate: from.toDate(), endDate: to.toDate() });
      setDateFilterOption(2);
    }

    TaskApi.getTaskTypes()
      .then(value => {
        setTaskTypes(value);
      })
      .finally(() => setIsLoading(false));
  }, [dateParam, statusParam, typeParam]);

  const handleCloseReExecute = (event: any, reason: any) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnack(false);
  };

  function Alert(props: any) {
    return <MuiAlert elevation={6} variant='filled' {...props} />;
  }

  function setSearchRequestField(field: string, value: string) {
    const updatedSearchRequest = {
      ...searchRequest,
    } as any;
    if (value) {
      updatedSearchRequest[field] = value;
    } else {
      delete updatedSearchRequest[field];
    }
    setSearchRequest(updatedSearchRequest);
  }

  function setSearchRequestStatus(field: string, value: string[]) {
    const updatedSearchRequest = {
      ...searchRequest,
    } as any;
    if (value) {
      updatedSearchRequest[field] = value;
    } else {
      delete updatedSearchRequest[field];
    }
    setSearchRequest(updatedSearchRequest);
  }

  function performSearch(): void {
    if (tableRef != undefined && tableRef.current != undefined && tableRef.current.onQueryChange != undefined) {
      tableRef.current.onQueryChange();
    }
  }

  function querySearch(query: Query<LogExecution>): Promise<QueryResult<LogExecution>> {
    let updatedSearchRequest = searchRequest;
    if (query.orderBy != undefined) {
      const orderChanged: Sort = {
        property: attToColumn.get(query.orderBy.field!)!,
        order: query.orderDirection,
      };
      updatedSearchRequest = {
        ...searchRequest,
        sortBy: orderChanged,
      } as ExecutionsFilter;
      setSearchRequest(updatedSearchRequest);
    }
    return new Promise((resolve, reject) => {
      ExecutionApi.list(query.pageSize, query.page + 1, updatedSearchRequest).then(response => {
        resolve({
          data: response.data,
          page: query.page,
          totalCount: response.total,
        });
      });
    });
  }

  function formatCustomDates(searchRequest: ExecutionsFilter, DATE_PATTERN = 'YYYY/MM/DD'): string {
    return (
      moment(searchRequest.dates.from, DATE_PATTERN).format('M/D/YYYY') +
      ' - ' +
      moment(searchRequest.dates.to, DATE_PATTERN).format('M/D/YYYY')
    );
  }

  function handleDateFilterSelection(number: number) {
    setDateFilterOption(number);
    const updatedSearchRequest = {
      ...searchRequest,
    };
    switch (number) {
      case 0:
        updatedSearchRequest['dates'] = {};
        setSearchRequest(updatedSearchRequest);
        performSearch();
        break;
      case 1:
        const oneMonthAgo = moment(new Date()).utc().startOf('day');
        oneMonthAgo.subtract(30, 'days');
        updatedSearchRequest['dates'] = {
          from: oneMonthAgo.toISOString(),
        };
        setSearchRequest(updatedSearchRequest);
        performSearch();
        break;
      case 2:
        setDatePickerOpen(true);
        break;
    }
  }

  return (
    <React.Fragment>
      <Box mb={'18px'}>
        <h1>Executions</h1>
      </Box>
      <ShowIf condition={isLoading}>
        <Paper>
          <LinearProgress style={{ marginTop: 30 }} />
        </Paper>
      </ShowIf>
      <ShowIf condition={!isLoading}>
        <ColoredLine />
        <div className={classes.searchFormContainer}>
          <FormControl className={classes.formControl}>
            <InputLabel id='task-type-picker-label'>Task Type</InputLabel>
            <Select
              labelId='task-type-picker-label'
              id='task-type-picker'
              value={searchRequest.taskType || ''}
              onChange={(event: any) => setSearchRequestField('taskType', event.target.value)}
            >
              <MenuItem value=''>
                <em>None</em>
              </MenuItem>
              {taskTypes.map(taskType => (
                <MenuItem key={taskType.id} value={taskType.id}>
                  {taskType.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl className={classes.formControl}>
            <InputLabel id='task-status-picker-label'>Task Status</InputLabel>
            <Select
              MenuProps={{
                variant: 'menu',
              }}
              labelId='task-status-picker-label'
              id='task-status-picker'
              multiple={true}
              value={searchRequest.status}
              onChange={(event: any) => setSearchRequestStatus('status', event.target.value)}
            >
              <MenuItem value={ExecutionStatus.IN_PROGRESS}>IN PROGRESS</MenuItem>
              <MenuItem value={ExecutionStatus.SUCCESS}>{ExecutionStatus.SUCCESS}</MenuItem>
              <MenuItem value={ExecutionStatus.WARN}>{ExecutionStatus.WARN}</MenuItem>
              <MenuItem value={ExecutionStatus.ERROR}>{ExecutionStatus.ERROR}</MenuItem>
              <MenuItem value={ExecutionStatus.CRITICAL}>{ExecutionStatus.CRITICAL}</MenuItem>
              <MenuItem value={ExecutionStatus.RETRY}>{ExecutionStatus.RETRY}</MenuItem>
            </Select>
          </FormControl>
          <TextField
            className={classes.searchInput}
            label='Reference'
            placeholder='Order / Contract'
            onChange={event => setSearchRequestField('reference', event.target.value)}
          />
          <TextField
            className={classes.searchInputLarge}
            label="Execution's Detail Message"
            placeholder='Ex. AssetTrigger: execution of BeforeUpdate'
            onChange={event => setSearchRequestField('detailMessage', event.target.value)}
          />
          <Button className={classes.searchButton} variant='contained' color='primary' onClick={() => performSearch()}>
            Search
          </Button>
        </div>
        <div className={classes.dateTabsContainer}>
          <ButtonGroup variant='contained' color='default' aria-label='contained primary button group'>
            <Button
              color='primary'
              variant={dateFilterOption === 0 ? 'contained' : 'outlined'}
              onClick={() => handleDateFilterSelection(0)}
            >
              All
            </Button>
            <Button
              color='primary'
              variant={dateFilterOption === 1 ? 'contained' : 'outlined'}
              onClick={() => handleDateFilterSelection(1)}
            >
              Last 30 Days
            </Button>
            <Button
              color='primary'
              variant={dateFilterOption === 2 ? 'contained' : 'outlined'}
              onClick={() => handleDateFilterSelection(2)}
            >
              <ShowIf condition={dateFilterOption == 2}>{formatCustomDates(searchRequest)}</ShowIf>
              <ShowIf condition={dateFilterOption != 2}>CUSTOM</ShowIf>
            </Button>
          </ButtonGroup>
        </div>
        <ExecutionsTable tableRef={tableRef} taskTypes={taskTypes} data={querySearch} />
      </ShowIf>
      <Snackbar open={openSnack} autoHideDuration={2000} onClose={handleCloseReExecute}>
        <Alert onClose={handleCloseReExecute} severity='success'>
          Task execution's triggered successfully!
        </Alert>
      </Snackbar>
      <Dialog open={datePickerOpen} aria-labelledby='alert-dialog-title' aria-describedby='alert-dialog-description'>
        <DateRangePicker
          initialDateRange={dateRange}
          definedRanges={[]}
          open={datePickerOpen}
          toggle={() => setDatePickerOpen(!datePickerOpen)}
          onChange={(range: DateRange) => {
            const updatedSearchRequest = {
              ...searchRequest,
            };
            const from = moment(range.startDate).utc().startOf('day');
            const to = moment(range.endDate).utc().endOf('day');
            updatedSearchRequest['dates'] = {
              from: from.toISOString(),
              to: to.toISOString(),
            };
            setSearchRequest(updatedSearchRequest);
            setDateRange(range);
          }}
        />
        <div className={classes.datePickerDialogButtonContainer}>
          <Button
            variant='contained'
            color='primary'
            onClick={() => {
              setDatePickerOpen(false);
              performSearch();
            }}
          >
            Apply
          </Button>
        </div>
      </Dialog>
    </React.Fragment>
  );
};

interface DateRange {
  startDate?: Date;
  endDate?: Date;
}
