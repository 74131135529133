import React from 'react';
import { useHistory } from 'react-router-dom';
import { Auth0Provider } from '@auth0/auth0-react';
import Config from '../config/Config';

const Auth0ProviderWithHistory = ({ children }: { children: any }) => {
  const history = useHistory();
  const urlParams = new URLSearchParams(window.location.search);
  const connection = urlParams.get('connection');

  const onRedirectCallback = (appState: any) => {
    history.push(appState?.returnTo || window.location.pathname);
  };

  return (
    <Auth0Provider
      domain={Config.auth0.domain}
      clientId={Config.auth0.clientId}
      redirectUri={window.location.origin + '/implicit/callback'}
      audience={Config.auth0.audience}
      onRedirectCallback={onRedirectCallback}
      connection={connection === 'okta' ? Config.auth0.connection : undefined}
    >
      {children}
    </Auth0Provider>
  );
};

export default Auth0ProviderWithHistory;
