import { ListItemText } from '@material-ui/core';
import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';

export const redirectUri = `${window.location.origin}/`;

// Basic component with logout button
export function LogoutButton() {
  const { logout } = useAuth0();

  const logoutHandler = async () => {
    await logout({ returnTo: `${window.location.origin}/logout` });
  };

  return <ListItemText onClick={logoutHandler}>Logout</ListItemText>;
}
