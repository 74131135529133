import React, { useState } from 'react';
import {
  FormControl,
  FormGroup,
  TextField,
  makeStyles,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  endTimeSelect: {
    marginLeft: "10px",
  },
}));

export function ChooseTime(props: any) {
  const classes = useStyles();

  return (
    <FormGroup row={true}>
      <FormControl>
        <TextField
          select
          variant="outlined"
          size="small"
          value={props.hour}
          onChange={props.onAtHourChange}
          SelectProps={{
            disabled: props.disabled,
            native: true,
          }}
        >
          {Array.from(Array(24).keys()).map((m) => (
            <option key={m} id={m.toString()}>
              {m.toString().length < 2 ? `${m}` : m}
            </option>
          ))}
        </TextField>
      </FormControl>
      <TextField
        select
        variant="outlined"
        size="small"
        value={props.minute}
        onChange={props.onAtMinuteChange}
        className={classes.endTimeSelect}
        SelectProps={{
          disabled: props.disabled,
          native: true,
        }}
      >
        {Array.from(Array(60).keys()).map((m) => (
          <option key={m} id={m.toString()}>
            {m.toString().length < 2 ? `${m}` : m}
          </option>
        ))}
      </TextField>
    </FormGroup>
  );
}
