import React from 'react';

export default function ShowIf(props: { condition: any; children: any }) {
  const { condition, children } = props;

  if (!condition) {
    return null;
  }

  return <React.Fragment>{children}</React.Fragment>;
}
