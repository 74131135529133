import React from 'react';

export default function ColoredLine(props: { height?: number }) {
    const { height } = props;
    return <React.Fragment><hr
        style={{
            marginTop: 30,
            color: 'black',
            border: 0,
            backgroundColor: '#fe4f05',
            height: height ?? 4
        }}
    /></React.Fragment>;
}
