import Config from '../config/Config';
import { axiosInstance } from './AxiosInstance';

const API_URL = Config.app.apiUrl;

export default abstract class taskApi {

    static getTaskTypes = (): Promise<TaskType[]> => {
        return axiosInstance.get(`${API_URL}/v1/taskTypes`);
    };

    static getTasks = (): Promise<Task[]> => {
        return axiosInstance.get(`${API_URL}/v1/tasks`);
    };

    static updateTaskType = (taskId: string, taskType: TaskType): Promise<void> => {
        return axiosInstance.put(`${API_URL}/v1/tasks/${taskId}`, taskType);
    };

    static executeTaskType = (taskId: string,payload?:any): Promise<void> => {
        if(payload) return axiosInstance.post(`${API_URL}/v1/tasks/${taskId}/execute`, {payload});
        return axiosInstance.post(`${API_URL}/v1/tasks/${taskId}/execute`);
    };
}

export interface TaskType {
    id: string;
    name: string;
}

export interface Task {
    id: string;
    name: string;
    state?: RuleState;
    schedule?: string;
    lastStatus?: string;
    lastExecution?: Date;
    message?: string;
}

export type RuleState = 'ENABLED' | 'DISABLED' | string;
