import React from 'react';
import { Box, Button, Typography } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';

export function Logout() {
    return (<Paper style={{ padding: '24px' }}>
          <Box>
      <Box mb={2}>
        <Typography variant="h5">You've logged out</Typography>
      </Box>
     <Button variant="outlined" size="medium" color="inherit" href="/" style={{
         color: 'white', background: '#fe5000',
     }}>
            Log in
          </Button>
    </Box>

      </Paper>);
}
