import axios, { AxiosInstance } from 'axios';
import { openSnackbar, Store } from '../common/Store';
import { SnackbarSeverity } from '../components/SnackbarData';
import { Auth0Client } from '@auth0/auth0-spa-js';
import Config from '../config/Config';

const axiosInstance: AxiosInstance = axios.create();
const auth0 = new Auth0Client({
  domain: Config.auth0.domain,
  client_id: Config.auth0.clientId,
  audience: Config.auth0.audience,
});

axiosInstance.interceptors.request.use(async config => {
  const token = await auth0.getTokenSilently();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

axiosInstance.interceptors.response.use(
  (response: any) => response.data,
  error => {
    console.log(error);
    if (error.response) {
      const message = error.response.data ? error.response.data.message : 'An unexpected error has occurred';
      console.log(message);
    }
    Store.dispatch(
      openSnackbar({
        message: `${error.response?.data?.message || error.message}`,
        severity: SnackbarSeverity.ERROR,
      }),
    );
    throw error;
  },
);

export { axiosInstance };
