import React, { useEffect, useState } from 'react';

import { UserInfoProvider } from './UserInfoContext';
import { UserInfo, UserRoleType } from '../services/User';
import UserApi from '../services/UserApi';
import { useAuth0 } from '@auth0/auth0-react';

export function UserContainer(props: any) {
  const { isAuthenticated } = useAuth0();
  const [userInfo, setuserInfo] = useState<UserInfo>();
  useEffect(() => {
    if (isAuthenticated) {
      UserApi.getInfo().then((data: UserInfo) => {
        setuserInfo(data);
      });
    }
  }, [isAuthenticated]);

  return userInfo && isAuthenticated ? (
    <UserInfoProvider value={userInfo}>{props.children}</UserInfoProvider>
  ) : (
    <UserInfoProvider value={{ role: UserRoleType.Biz }}>{props.children}</UserInfoProvider>
  );
}
