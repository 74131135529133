import React, { useEffect, useState } from 'react';
import ShowIf from '../../components/ShowIf';
import { LinearProgress, Paper, Typography } from '@material-ui/core';
import ExecutionDetailsTable from './ExecutionDetailsTable';
import { makeStyles } from '@material-ui/core/styles';
import ExecutionApi from '../../services/ExecutionApi';
import { LogExecution } from '../../services/LogExecution';
import TaskApi, { TaskType } from '../../services/TaskApi';
import { StatusIcon } from '../../common/StatusIcon';
import moment from 'moment';
import ColoredLine from '../../components/ColoredLine';
import PastExecutionsTable from './PastExecutionsTable';

const useStyles = makeStyles({
    fieldLabel: {
        paddingRight: '15px', 
        fontWeight: 600,
    },
    fieldValue: {
        paddingRight: '15px', 
        fontSize: '1rem',
    },
    searchFormContainer: {
        display: 'flex',
        alignItems: 'baseline',
        marginBottom: '30px',
        marginTop: '30px',
    },
    searchInput: {
        width: '200px',
        height: '40px',
        marginRight: '40px',
    },
    searchButton: {
        width: '150px',
        height: '30px',
    },

});

export default function ExecutionDetails(props: { location: any, match: { params: { id: string } } }) {

    const [isLoading, setIsLoading] = useState(false);
    const [task, setTask] = useState<LogExecution | undefined>(undefined);
    const [taskTypes, setTaskTypes] = React.useState<TaskType[]>([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(25);
    const [rows, setRows] = React.useState<LogExecution[]>([]);
    const [title, setTile] = useState<string>(localStorage.getItem('ORDER_TITLE') || '');

    const classes = useStyles();

    const id = props.match.params.id;

    useEffect(() => {
        setIsLoading(true);
        TaskApi.getTaskTypes()
            .then((value: TaskType[]) => {
                setTaskTypes(value);
            })
            .catch(() => setIsLoading(false));

        ExecutionApi.getById(id)
            .then(value => {
                console.log(value);
                setTask(value);
                setTile(value.label);
                performSearch(value);
            })
            .finally(() => setIsLoading(false));
    }, [id]);

    useEffect(() => {
        performSearch(task);
    }, [rowsPerPage, page]);

    function performSearch(task?: LogExecution) {
        const rows = task?.context?.slice(page * rowsPerPage, (page + 1) * rowsPerPage);
        setRows(rows || []);
    }

    return (
        <React.Fragment>
            <h1>{title} </h1>
            <ShowIf condition={isLoading}>
                <Paper>
                    <LinearProgress style={{ marginTop: 30 }}/>
                </Paper>
            </ShowIf>
            <ShowIf condition={!isLoading}>
                <ColoredLine />
                <br/>
                <div>
                    <div style={{ display: 'flex', flexDirection: 'row', marginTop: 10, marginLeft: 20}}>
                        <div style={{ display: 'flex', flex: '0 0 20%', flexDirection: 'row'}}>
                            <Typography className={classes.fieldLabel} variant='caption'>Task</Typography>
                        </div>
                        <div style={{ display: 'flex', flex: '0 0 20%', flexDirection: 'row'}}>
                            <Typography className={classes.fieldLabel} variant='caption'>Type</Typography>
                        </div>
                        <div style={{ display: 'flex', flex: '0 0 20%', flexDirection: 'row'}}>
                            <Typography className={classes.fieldLabel} variant='caption'>Username</Typography>
                        </div>
                        <div style={{ display: 'flex', flex: '0 0 20%', flexDirection: 'row'}}>
                            <Typography className={classes.fieldLabel} variant='caption'>Started</Typography>
                        </div>
                        <div style={{ display: 'flex', flex: '0 0 20%', flexDirection: 'row'}}>
                            <Typography className={classes.fieldLabel} variant='caption'>Ended</Typography>
                        </div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', marginLeft: 20 }}>
                        <div style={{ display: 'flex', flex: '0 0 20%', flexDirection: 'row'}}>
                            <Typography variant='body1'>{taskTypes.find(value => value.id == task?.task)?.name || ''}</Typography>
                        </div>
                        <div style={{ display: 'flex', flex: '0 0 20%', flexDirection: 'row'}}>
                            <Typography variant='body1'>{task?.type || ''}</Typography>
                        </div>
                        <div style={{ display: 'flex', flex: '0 0 20%', flexDirection: 'row'}}>
                            <Typography variant='body1'>{task?.username || ''}</Typography>
                        </div>
                        <div style={{ display: 'flex', flex: '0 0 20%', flexDirection: 'row'}}>
                            <Typography variant='body1'>{moment(task?.startedAt, "YYYY-MM-DD[T]HH:mm:ss.SSS[Z]").format("M/D/YYYY h:mm:ss a")}</Typography>
                        </div>
                        <div style={{ display: 'flex', flex: '0 0 20%', flexDirection: 'row'}}>
                            <Typography variant='body1'>{moment(task?.endedAt, "YYYY-MM-DD[T]HH:mm:ss.SSS[Z]").format("M/D/YYYY h:mm:ss a")}</Typography>
                        </div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', marginTop: 10, marginLeft: 20 }}>
                        <div style={{ display: 'flex', flex: '0 0 20%', flexDirection: 'row'}}>
                            <Typography className={classes.fieldLabel} variant='caption'>Status</Typography>
                        </div>
                        <div style={{ display: 'flex', flex: '0 0 80%', flexDirection: 'row'}}>
                            <Typography className={classes.fieldLabel} variant='caption'>Message</Typography>
                        </div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', marginLeft: 20 }}>
                        <div style={{ display: 'flex', flex: '0 0 20%', flexDirection: 'row'}}>
                            <Typography variant='body1'>{task?.status || ''}</Typography>
                            <StatusIcon value={task?.status}/>
                        </div>
                        <div style={{ display: 'flex', flex: '0 0 80%', flexDirection: 'row'}}>
                            <Typography variant='body1'>{task?.message || ''}</Typography>
                        </div>
                    </div>

                </div>
                {/*<div style={{ marginTop: 150}}>*/}
                <div>

                    <h3>{'Details'}</h3>
                </div>

                <ExecutionDetailsTable data={task?.details || []}/>

                <ShowIf condition={task?.executions != undefined && task?.executions.length > 1}>
                    <div>
                        <h3>{'Times Processed'}</h3>
                    </div>

                    <PastExecutionsTable currentExecutionId={task?.id || ''} data={task?.executions || []}/>
                </ShowIf>
            </ShowIf>
        </React.Fragment>
    );
}
