import React from "react";
import Paper from "@material-ui/core/Paper";
import {
    CircularProgress,
    IconButton,
    TablePagination,
    Tooltip,
    withStyles,
} from "@material-ui/core";
import PlayCircleFilledSharpIcon from "@material-ui/icons/PlayCircleFilledSharp";
import ShowIf from "../../components/ShowIf";
import TaskApi from '../../services/ExecutionApi';
import { openSnackbar, Store } from "../../common/Store";
import { SnackbarSeverity } from "../../components/SnackbarData";
import { LogExecution, ExecutionStatus } from "../../services/LogExecution";
import { StatusIcon } from "../../common/StatusIcon";
import { TaskType } from "../../services/TaskApi";
import moment from "moment";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import MaterialTable, { Column, Query, QueryResult } from 'material-table';
import { Link } from 'react-router-dom';

function formatDate(date: string) {
    if (date) {
        return moment(date, "YYYY-MM-DD[T]HH:mm:ss.SSS[Z]").format("M/D/YYYY h:mm a");
    }
    return "Never";
}

export default function ExecutionsTable(props: {
    data: (query: Query<LogExecution>) => Promise<QueryResult<LogExecution>>;
    tableRef: any
    taskTypes: TaskType[];
}) {
    const {
        data,
        tableRef,
        taskTypes,
    } = props;
    const [pageSize, setPageSize] = React.useState(25);
    const [rowsPerPage] = React.useState<number[]>([5, 10, 25]);
    const columns: Column<LogExecution>[] = [
        { title: 'Id', field: "id", defaultSort: 'desc', render: (row) => <Link onClick={() => localStorage.setItem('ORDER_TITLE', row.label)} to={{ pathname: `/executions/${row.id}`, state: { fromDashboard: true } }} target='_blank' > {row.id} </Link> },
        {
            title: 'Task',
            field: "task",
            sorting: false,
            cellStyle: { width: "15%" },
            render: (row) =>
                taskTypes.find((type) => row.task === type.id)?.name || row.task,
        },
        {
            title: 'Execution Time',
            field: "startedAt",
            cellStyle: { width: "15%" },
            render: (row) => formatDate(row.startedAt),
        },
        {
            title: 'Status',
            field: "status",
            sorting: false,
            render: (row) => <StatusIcon value={row.status} />,
        },
        { title: 'Reference', field: "externalRef" },
        { title: 'Message', field: "message", sorting: false, cellStyle: { width: "40%" } },
        {
            title: 'Actions',
            sorting: false,
            cellStyle: { textAlign: "center" },
            render: (row) => <ReExecuteButton row={row} />,
        },
    ];

    return (
        <Paper>
			<MaterialTable
                tableRef={tableRef}
                columns={columns}
                data={data}
                options={{
                    showTitle: false,
                    headerStyle: {
                        fontWeight: "bolder"
                    },
                    pageSizeOptions: rowsPerPage,
                    pageSize: pageSize,
                    emptyRowsWhenPaging: false
                }}
                components={{
                    Toolbar: () => null,
                    Pagination: (props) => (
                        <TablePagination
                            {...props}
                            onChangeRowsPerPage={(event: React.ChangeEvent<HTMLInputElement>) => {
                                props.onChangeRowsPerPage(event);
                                setPageSize(parseInt(event.target.value, 10));
                            }}
                        />
                    ),
                }}
            />
		</Paper>
    );
}

function ReExecuteButton(props: { row: LogExecution }) {
    const StyledButton = withStyles({
        root: {
            marginRight: "5px",
            marginLeft: "5px",
        },
    })(Button);

    const { row } = props;
    const [openExecute, setOpenExecute] = React.useState(false);
    const [executing, setExecuting] = React.useState(false);

    const handleClickOpenExecute = () => {
        setOpenExecute(true);
    };

    const handleClickCloseExecute = () => {
        setOpenExecute(false);
    };

    function triggerExecution(row: LogExecution) {
        const { id } = row;
        setExecuting(true);
        TaskApi.triggerExecution(id)
        .then((_) => {
            setTimeout(function () {
                Store.dispatch(
                    openSnackbar({
                        message: "Successfully submitted Tasks execution.",
                        severity: SnackbarSeverity.SUCCESS,
                    })
                );
                setOpenExecute(false);
                setExecuting(false);
            }, 2000);
        })
        .catch(() => {
            setExecuting(false);
        });
    }

    return (
        <div>
			<ShowIf
                condition={
                    row.status == ExecutionStatus.ERROR || row.status == ExecutionStatus.CRITICAL || row.status == ExecutionStatus.RETRY
                }
            >
				<Tooltip title="Execute">
					<IconButton
                        style={{ padding: "1px" }}
                        aria-label="execute"
                        onClick={handleClickOpenExecute}
                    >
						<PlayCircleFilledSharpIcon />
					</IconButton>
				</Tooltip>
			</ShowIf>
			<Dialog
                open={openExecute}
                onClose={handleClickCloseExecute}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
				<DialogTitle id="alert-dialog-title">Re-Execute</DialogTitle>
				<DialogContent>
					{`Are you sure you want to re-execute process: `}
                    <b>{`${row.label}`}</b>?
				</DialogContent>
				<br />
				<DialogActions>
					<div
                        style={{
                            justifyContent: "flex-end",
                            display: "flex",
                        }}
                    >
						<StyledButton
                            color="secondary"
                            variant="outlined"
                            onClick={handleClickCloseExecute}
                        >
							Cancel
						</StyledButton>

						<div
                            style={{
                                display: "flex",
                                alignItems: "center",
                            }}
                        >
							<div
                                style={{
                                    position: "relative",
                                }}
                            >
								<Button variant="contained"
                                    color="primary"
                                    autoFocus
                                    disabled={executing}
                                    onClick={() => triggerExecution(row)} >
									OK
								</Button>
                                {executing && (
                                    <CircularProgress
                                        size={24}
                                        style={{
                                            position: "absolute",
                                            top: "50%",
                                            left: "50%",
                                            marginTop: -12,
                                            marginLeft: -12,
                                        }}
                                    />
                                )}
							</div>
						</div>
					</div>
				</DialogActions>
			</Dialog>
		</div>
    );
}
