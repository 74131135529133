import { Button, Tooltip } from '@material-ui/core';

import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import HighlightOffOutlinedIcon from '@material-ui/icons/HighlightOffOutlined';
import ReportProblem from '@material-ui/icons/ReportProblem';
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import ReplayOutlinedIcon from '@material-ui/icons/ReplayOutlined';
import React from 'react';
import ShowIf from '../components/ShowIf';

export function StatusIcon(props: any) {
    const showTooltip = props.toolTip != undefined ? props.toolTip : true;
    let status = () => {
        if (props.value === 'IN_PROGRESS') return 'waiting-status';
        if (props.value === 'SUCCESS' || props.value === 'SUCCESS_PARTIAL') return 'completed-status';
        if (props.value === 'ERROR' || props.value === 'ERROR_PARTIAL') return 'error-status';
        if (props.value === 'CRITICAL') return 'error-status';
        if (props.value === 'WARN'  || props.value === 'WARN_PARTIAL') return 'warning-status';
        if (props.value === 'INFO') return 'info-status';
        if (props.value === 'RETRY') return 'info-status';
        return 'waiting-status';
    };
    let icon = () => {
        if (props.value === 'IN_PROGRESS') return <QueryBuilderIcon/>;
        if (props.value === 'SUCCESS' || props.value === 'SUCCESS_PARTIAL') return <CheckCircleOutlineIcon/>;
        if (props.value === 'ERROR' || props.value === 'ERROR_PARTIAL') return <HighlightOffOutlinedIcon/>;
        if (props.value === 'CRITICAL') return <ReportProblem/>;
        if (props.value === 'WARN' || props.value === 'WARN_PARTIAL') return <ErrorOutlineIcon/>;
        if (props.value === 'INFO') return <InfoOutlinedIcon/>;
        if (props.value === 'RETRY') return <ReplayOutlinedIcon/>;
        return <QueryBuilderIcon/>;
    };
    return (
        <React.Fragment>
            <ShowIf condition={showTooltip}>
                <Tooltip title={props.value || ''}>
                    <Button style={{ padding: '0px' }}>
                        <span className={status()}>
                          {icon()}
                        </span>
                    </Button>
                </Tooltip>
            </ShowIf>
            <ShowIf condition={!showTooltip}>
                <Button style={{ padding: '0px' }}>
                        <span className={status()}>
                                {icon()}
                        </span>
                </Button>
            </ShowIf>
        </React.Fragment>
    );
}
