import { LogExecution, ExecutionStatus } from './LogExecution';
import Config from '../config/Config';
import { axiosInstance } from './AxiosInstance';

const API_URL = Config.app.apiUrl;

export default abstract class executionApi {

  static list = (limit: number, page: number, executionsFilter: ExecutionsFilter): Promise<Executions> => {
    return axiosInstance.post(`${API_URL}/v1/executions/searches/by-filter?limit=${limit}&page=${page}`, executionsFilter);
  };
  static getById = (taskId: string | null): Promise<LogExecution> => {
    return axiosInstance.get(`${API_URL}/v1/executions/${taskId}`);
  };
  static triggerExecution = (taskId: string | null): Promise<LogExecution> => {
    return axiosInstance.post(`${API_URL}/v1/executions/${taskId}/re-execute`);
  };

}

export interface Executions {
    data: LogExecution[];
    pages: number,
    total: number
}

export type SORT_FIELDS = 'id' | 'external_ref' | 'started_at';

export interface Sort {
    property: SORT_FIELDS;
    order: 'asc' | 'desc';
}

export interface ExecutionsFilter {
  reference?: string;
  taskType?: string;
  label?: string;
  detailMessage?: string;
  status?: ExecutionStatus[];
  dates: {
    from?: string;
    to?: string;
  };
  sortBy?: Sort;
}
