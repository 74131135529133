import { FormControl, FormLabel, styled } from "@material-ui/core";

export const StyledFormControl = styled(FormControl)({
  marginRight: "20px",
  marginBottom: "10px",
  minWidth: "50%",
  display: "inline-flex",
  textAlign: "left",
  float: "left",
  flex: "flex-grow",
});

export const StyledFormLabel = styled(FormLabel)({
  marginBottom: "8px",
  minWidth: "25%",
});
