import { Box, FormControl, FormLabel, Grid } from '@material-ui/core';
import React, { useEffect, useState } from 'react';

import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { ChooseTime } from './ChooseTime';

export function Hourly(props: any) {
    const [every, setEvery] = useState<boolean>(true);
    const [value, setValue] = useState(props.value);
    const [hours, setHours] = useState(value[1].split('/')[0] === '*' ? 0 : value[1].split('/')[0]);
    const [hourly, setHourly] = useState(value[1].split('/')[0] === '*' ? 1 : value[1].split('/')[1]);
    const [minutes, setMinutes] = useState(value[0]);
    const [withStartDate, setWithStartDate] = useState<boolean>((hours == 0 && minutes == 0) ? false : true);
    useEffect(() => {
        setValue(props.value);
    }, [props.value]);

    useEffect(() => {
        const value = props.value;
        if (value[2] === '?') {
            setEvery(false);
        } else {
            setEvery(true);
        }
    }, [props.value]);

    const onHoursChange = (e: any) => {
        const hoursPatterns = withStartDate ? hours : '0';
        let val = [withStartDate ? minutes : '0', (withStartDate || e.target.value > 1) ? `${hoursPatterns}/${e.target.value}` : '*', '*', '*', '?', '*'];
        setHourly(e.target.value);
        props.onChange(val);
    };

    const onAtHourChange = (e: any) => {
        const hoursNew = withStartDate ? e.target.value : '0';
        let val = [withStartDate ? minutes : '0', (withStartDate || hourly > 1) ? `${hoursNew}/${hourly}` : '*', '*', '*', '?', '*'];
        setHours(e.target.value);
        props.onChange(val);
    };

    const onAtMinuteChange = (e: any) => {
        const hoursPatterns = withStartDate ? hours : '0';
        let val = [withStartDate ? e.target.value : '0', (withStartDate || hourly > 1) ? `${hoursPatterns}/${hourly}` : '*', '*', '*', '?', '*'];
        setMinutes(e.target.value);
        props.onChange(val);
    };

    const handleStartDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const hoursPatterns = event.target.checked ? hours : '0';
        let val = [event.target.checked ? minutes : '0', (event.target.checked || hourly > 1) ? `${hoursPatterns}/${hourly}` : '*', '*', '*', '?', '*'];
        setWithStartDate(event.target.checked);
        console.log(val);
        props.onChange(val);
    };

    return (<div>
        <Grid item xs={12}>
            <Box>
                <FormLabel>Every hour(s)</FormLabel>
            </Box>
        </Grid>
        <Grid item xs={12}>
            <Box>
              <TextField
                  id="outlined-number"
                  defaultValue={hourly}
                  onChange={onHoursChange}
                  type="number"
                  InputLabelProps={{
                      disabled: every ? false : true, shrink: true,
                  }}
                  inputProps={{ min: 1 }}
                  margin="dense"
                  variant="outlined"
              />
            </Box>
        </Grid>
        <Grid item xl={12} justify="center">
             <Box>
                  <FormControlLabel
                      value="end"
                      control={<Checkbox checked={withStartDate} onChange={handleStartDateChange} color="primary" />}
                      label="Start time"
                      labelPlacement="end"
                  />
                 <div />
                  <ChooseTime
                      disabled={!withStartDate}
                      hour={hours}
                      minute={minutes}
                      onAtMinuteChange={onAtMinuteChange}
                      onAtHourChange={onAtHourChange}
                  />
                   </Box>
        </Grid>
    </div>);
}
