import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Box, Button, ButtonGroup, LinearProgress, Menu, MenuItem, Paper } from '@material-ui/core';
import ShowIf from '../../components/ShowIf';
import ColoredLine from '../../components/ColoredLine';
import { Bar, BarChart, CartesianGrid, LabelList, Tooltip, XAxis, YAxis } from 'recharts';
import { makeStyles } from '@material-ui/core/styles';
import { ExecutionStatus } from '../../services/LogExecution';
import DashboardApi, { DashboardFilter, ExecutionDayInfo, QuarterInfo } from '../../services/DashboardApi';
import moment from 'moment';
import Dialog from '@material-ui/core/Dialog';
import { DateRangePicker } from 'materialui-daterange-picker';
import { DateRange } from 'materialui-daterange-picker/dist';
import { ExecutionsFilter } from '../../services/ExecutionApi';

const useStyles = makeStyles({
  dateTabsContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: '30px',
  },
  datePickerDialogButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '10px',
  },
  circle: {
    display: 'flex',
    width: '100px',
    height: '100px',
    backgroundColor: 'red',
    borderRadius: '50%',
  },
  bar: {
    cursor: 'pointer',
  },
});

const threeMonthAgo = new Date();
threeMonthAgo.setMonth(new Date().getMonth() - 3);

const defaultDates = {
  from: new Date().toISOString(),
  to: new Date().toISOString(),
};

export interface StackedBarChartData {
  name: string;
  fullDate: string;
  success: number;
  warning: number;
  error: number;
  critical: number;
  value: number;
}

export interface Day {
  shortDate: string;
  fullDate: string;
}

const ASSET_CREATION_HEXA_COLOR = '#ED4B00'; //violenta
const ASSET_WARRANTY_HEXA_COLOR = '#ff9bad'; //verde
const CONTRACT_ACTIVATION_HEXA_COLOR = '#662e9c'; //'#ff9bad'; //pink
const PAAS_ASSET_CREATION_HEXA_COLOR = '#00b89e'; //turqueza
const PART_MAPPING_CREATION_PROCESS_HEXA_COLOR = '#00b89e'; //turqueza
const PART_MAPPING_UPDATE_PROCESS_HEXA_COLOR = '#00b89e'; //turqueza
const ASSET_UPDATE_PROCESS_HEXA_COLOR = '#00b89e'; //turqueza

function formatDate(date: string) {
  if (date) {
    return moment(date, 'YYYY-MM-DD[T]HH:mm:ss.SSS[Z]').format('M/D');
  }
  return 'Never';
}

//'paas_asset_creation_new', 'asset_creation_new', 'asset_warranty', 'contract_activation_new','asset_genealogy_creation','asset_pm_lookup_update','asset_update',part_mapping_creation

const ASSET_CREATION_PROCESS_TASK_NAME = 'asset_creation_new';
const ASSET_CREATION_ASSET_COUNT_PROCESS_TASK_NAME = 'asset_creation_asset_count';
const PAAS_ASSET_CREATION_PROCESS_TASK_NAME = 'paas_asset_creation_new';
const ASSET_WARRANTY_PROCESS_TASK_NAME = 'asset_warranty';
const ACTIVATE_CONTRACT_PROCESS_TASK_NAME = 'contract_activation_new';
const PART_MAPPING_CREATION_PROCESS_TASK_NAME = 'part_mapping_creation';
const PART_MAPPING_UPDATE_PROCESS_TASK_NAME = 'asset_pm_lookup_update';
const ASSET_UPDATE_PROCESS_TASK_NAME = 'asset_update';
const ASSET_GENEOLOGY_CREATION_TASK_NAME = 'asset_genealogy_creation';

export default () => {
  const [isLoading, setIsLoading] = useState(false);
  const [dateFilterOption, setDateFilterOption] = React.useState(1);
  const [searchRequest, setSearchRequest] = useState<DashboardFilter>({ dates: defaultDates });
  const [datePickerOpen, setDatePickerOpen] = React.useState<boolean>(false);
  const [dateRange, setDateRange] = React.useState<DateRange>({});

  const [assetCreationData, setAssetCreationData] = React.useState<StackedBarChartData[]>([]);
  const [assetCreationAssetCountData, setAssetCreationAssetCountData] = React.useState<StackedBarChartData[]>([]);
  const [paasAssetCreationData, setPaasAssetCreationData] = React.useState<StackedBarChartData[]>([]);
  const [assetWarrantyData, setAssetWarrantyData] = React.useState<StackedBarChartData[]>([]);
  const [contractActivationData, setContractActivationData] = React.useState<StackedBarChartData[]>([]);

  const [partMappingCreationData, setPartMappingCreationData] = React.useState<StackedBarChartData[]>([]);
  const [partMappingUpdateData, setPartMappingUpdate] = React.useState<StackedBarChartData[]>([]);
  const [assetUpdateData, setAssetUpdateData] = React.useState<StackedBarChartData[]>([]);
  const [assetGeneologyCreationData, setAssetGeneologyCreationData] = React.useState<StackedBarChartData[]>([]);

  const [quarter, setQuarter] = useState<QuarterInfo>();
  const [quarters, setQuarters] = useState<QuarterInfo[]>();
  const [quarterSelectionAnchorEl, setQuarterSelectionAnchorEl] = React.useState<null | HTMLElement>(null);

  const quarterSelectionOpen = Boolean(quarterSelectionAnchorEl);
  const handleClickQuarterSelection = (event: React.MouseEvent<HTMLButtonElement>) => {
    setQuarterSelectionAnchorEl(event.currentTarget);
  };
  const handleCloseQuarterSelection = () => {
    setQuarterSelectionAnchorEl(null);
  };

  const classes = useStyles();

  const handleRefreshStackedBarData = useCallback((data: ExecutionDayInfo[], filter: DashboardFilter) => {
    const days = buildDaysList(filter);
    const assetCreationData: StackedBarChartData[] = calculateStackedBarData(
      ASSET_CREATION_PROCESS_TASK_NAME,
      days,
      data,
    );
    setAssetCreationData(assetCreationData);
    const assetCreationAssetCountData: StackedBarChartData[] = calculateStackedBarData(
      ASSET_CREATION_ASSET_COUNT_PROCESS_TASK_NAME,
      days,
      data,
    );
    setAssetCreationAssetCountData(assetCreationAssetCountData);
    const paasAssetCreationData: StackedBarChartData[] = calculateStackedBarData(
      PAAS_ASSET_CREATION_PROCESS_TASK_NAME,
      days,
      data,
    );
    setPaasAssetCreationData(paasAssetCreationData);
    const assetWarrantyData: StackedBarChartData[] = calculateStackedBarData(
      ASSET_WARRANTY_PROCESS_TASK_NAME,
      days,
      data,
    );
    setAssetWarrantyData(assetWarrantyData);
    const contractActivationData: StackedBarChartData[] = calculateStackedBarData(
      ACTIVATE_CONTRACT_PROCESS_TASK_NAME,
      days,
      data,
    );
    setContractActivationData(contractActivationData);
    const partMappingCreationData: StackedBarChartData[] = calculateStackedBarData(
      PART_MAPPING_CREATION_PROCESS_TASK_NAME,
      days,
      data,
    );
    setPartMappingCreationData(partMappingCreationData);
    const partMappingUpdateData: StackedBarChartData[] = calculateStackedBarData(
      PART_MAPPING_UPDATE_PROCESS_TASK_NAME,
      days,
      data,
    );
    setPartMappingUpdate(partMappingUpdateData);
    const assetUpdateData: StackedBarChartData[] = calculateStackedBarData(ASSET_UPDATE_PROCESS_TASK_NAME, days, data);
    setAssetUpdateData(assetUpdateData);
    const assetGeneologyCreationData: StackedBarChartData[] = calculateStackedBarData(
      ASSET_GENEOLOGY_CREATION_TASK_NAME,
      days,
      data,
    );
    setAssetGeneologyCreationData(assetGeneologyCreationData);
  }, []);

  const handleRefreshChartsData = useCallback(
    (data: ExecutionDayInfo[], filter: DashboardFilter) => {
      handleRefreshStackedBarData(data, filter);
    },
    [handleRefreshStackedBarData],
  );

  const refreshDashboardData = useCallback(
    (filter: DashboardFilter) => {
      setIsLoading(true);
      DashboardApi.getDashboardInfoByFilter(filter)
        .then(value => {
          handleRefreshChartsData(value, filter);
        })
        .finally(() => setIsLoading(false));
    },
    [handleRefreshChartsData],
  );

  const handleDateFilterSelection = useCallback((number: number, quarterInfo?: QuarterInfo) => {
    setDateFilterOption(number);
    switch (number) {
      case 0:
        const from = moment(new Date()).utc().startOf('day');
        from.subtract(30, 'days');
        const to = moment(new Date()).utc().add(1, 'day').endOf('day');
        setSearchRequest({ dates: { from: from.toISOString(), to: to.toISOString() } });
        break;
      case 1:
        const filterQuarter = {
          dates: { from: quarterInfo?.StartDate + 'T00:00:00.000Z', to: quarterInfo?.EndDate + 'T00:00:00.000Z' },
        };
        setSearchRequest(filterQuarter);
        break;
      case 2:
        setDatePickerOpen(true);
        break;
    }
  }, []);

  const handleQuarterSelection = useCallback(
    (quarterInfo: QuarterInfo) => {
      handleCloseQuarterSelection();
      setQuarter(quarterInfo);
      handleDateFilterSelection(1, quarterInfo);
    },
    [handleDateFilterSelection],
  );

  const getQuarterDetails = useCallback(() => {
    setIsLoading(true);
    DashboardApi.getCurrentQuarterInfo().then(value => {
      setQuarters(value);
      setQuarter(value[0]);
      handleDateFilterSelection(1, value[0]);
    });
  }, [handleDateFilterSelection]);

  useEffect(() => {
    getQuarterDetails();
  }, [getQuarterDetails]);

  useEffect(() => {
    refreshDashboardData(searchRequest);
  }, [refreshDashboardData, searchRequest]);

  // function calculatePieChartValue(data: ExecutionDayInfo[], taskName: string, status: ExecutionStatus[]) {
  //     return data.filter(value => value.task === taskName && status.indexOf(value.status) !== -1).map(a => a.value).reduce(function (a, b) { return a + b; }, 0)
  // }

  function calculateStackedBarData(taskName: string, days: Day[], data: ExecutionDayInfo[]): StackedBarChartData[] {
    const chartData: StackedBarChartData[] = [];
    const reducer = (accumulator: number, currentValue: number) => accumulator + currentValue;
    days.forEach(day => {
      chartData.push({
        name: day.shortDate,
        fullDate: day.fullDate,
        critical: data
          .filter(
            d => d.task === taskName && formatDate(d.day) === day.shortDate && d.status === ExecutionStatus.CRITICAL,
          )
          .map(a => a.value)
          .reduce(reducer, 0),
        error: data
          .filter(d => d.task === taskName && formatDate(d.day) === day.shortDate && d.status === ExecutionStatus.ERROR)
          .map(a => a.value)
          .reduce(reducer, 0),
        success: data
          .filter(
            d => d.task === taskName && formatDate(d.day) === day.shortDate && d.status === ExecutionStatus.SUCCESS,
          )
          .map(a => a.value)
          .reduce(reducer, 0),
        warning: data
          .filter(d => d.task === taskName && formatDate(d.day) === day.shortDate && d.status === ExecutionStatus.WARN)
          .map(a => a.value)
          .reduce(reducer, 0),
        value: data
          .filter(d => d.task === taskName && formatDate(d.day) === day.shortDate)
          .map(a => a.value)
          .reduce(reducer, 0),
      });
    });
    return chartData;
  }

  function buildDaysList(filter: DashboardFilter): Day[] {
    const days = new Set<string>();
    const day = new Date(filter.dates.from);
    const dayTo = new Date(filter.dates.to);
    const fullDays: Day[] = [];
    dayTo.setHours(0, 0, 0, 0);
    while (day <= dayTo) {
      days.add(formatDate(day.toISOString()));
      day.setDate(day.getDate() + 1);
      day.setHours(0, 0, 0, 0);
      fullDays.push({
        shortDate: formatDate(day.toISOString()),
        fullDate: moment(day.toISOString(), 'YYYY-MM-DD[T]HH:mm:ss.SSS[Z]').format('M-D-Y'),
      });
    }
    return fullDays;
  }

  function formatCustomDates(searchRequest: ExecutionsFilter, DATE_PATTERN = 'YYYY/MM/DD'): string {
    return (
      moment(searchRequest.dates.from, DATE_PATTERN).format('M/D/YYYY') +
      ' - ' +
      moment(searchRequest.dates.to, DATE_PATTERN).format('M/D/YYYY')
    );
  }

  return (
    <React.Fragment>
      <Box mb={'18px'}>
        <h1>Dashboard</h1>
      </Box>
      <ShowIf condition={isLoading || !quarter}>
        <Paper>
          <LinearProgress style={{ marginTop: 30 }} />
        </Paper>
      </ShowIf>
      <ShowIf condition={!isLoading}>
        <ColoredLine />
        <div className={classes.dateTabsContainer}>
          <ButtonGroup variant='contained' color='default' aria-label='contained primary button group'>
            <Button
              color='primary'
              id='quarter-button'
              variant={dateFilterOption === 1 ? 'contained' : 'outlined'}
              aria-controls={quarterSelectionOpen ? 'quarter-menu' : undefined}
              aria-haspopup='true'
              aria-expanded={quarterSelectionOpen ? 'true' : undefined}
              onClick={handleClickQuarterSelection}
            >
              {quarter?.FullyQualifiedLabel}
            </Button>
            <Menu
              id='quarter-menu'
              anchorEl={quarterSelectionAnchorEl}
              open={quarterSelectionOpen}
              onClose={handleCloseQuarterSelection}
              MenuListProps={{
                'aria-labelledby': 'quarter-button',
              }}
            >
              {quarters?.map((quarter, index) => {
                return (
                  <MenuItem key={index} onClick={() => handleQuarterSelection(quarter)}>
                    {quarter?.FullyQualifiedLabel}
                  </MenuItem>
                );
              })}
            </Menu>
            <Button
              color='primary'
              variant={dateFilterOption === 0 ? 'contained' : 'outlined'}
              onClick={() => handleDateFilterSelection(0)}
            >
              Last 30 Days
            </Button>

            <Button
              color='primary'
              variant={dateFilterOption === 2 ? 'contained' : 'outlined'}
              onClick={() => handleDateFilterSelection(2)}
            >
              <ShowIf condition={dateFilterOption === 2}>{formatCustomDates(searchRequest)}</ShowIf>
              <ShowIf condition={dateFilterOption !== 2}>CUSTOM</ShowIf>
            </Button>
          </ButtonGroup>
        </div>
        <div>
          <div style={{ display: 'flex', flexDirection: 'row', marginTop: 10, marginLeft: 20, alignItems: 'center' }}>
            <div style={{ flex: '0 0 50%', alignItems: 'center' }}>
              <StackedBarChart
                title='Asset Creation - Orders %'
                task={ASSET_CREATION_PROCESS_TASK_NAME}
                data={assetCreationData}
                color={ASSET_CREATION_HEXA_COLOR}
                yLabel={'Orders %'}
                showPercentageValues
              />
            </div>
            <div style={{ flex: '0 0 50%', alignItems: 'center' }}>
              <StackedBarChart
                title='Asset Creation - Assets %'
                task={ASSET_CREATION_PROCESS_TASK_NAME}
                data={assetCreationAssetCountData}
                color={ASSET_CREATION_HEXA_COLOR}
                yLabel={'Assets %'}
                showPercentageValues
              />
            </div>
          </div>
          <div style={{ display: 'flex', flexDirection: 'row', marginTop: 10, marginLeft: 20, alignItems: 'center' }}>
            <div style={{ flex: '0 0 50%', alignItems: 'center' }}>
              <StackedBarChart
                title='Asset Creation - Orders'
                task={ASSET_CREATION_PROCESS_TASK_NAME}
                data={assetCreationData}
                color={ASSET_CREATION_PROCESS_TASK_NAME}
                yLabel={'Orders'}
              />
            </div>
            <div style={{ flex: '0 0 50%', alignItems: 'center' }}>
              <StackedBarChart
                title='Asset Creation - Assets'
                task={ASSET_CREATION_PROCESS_TASK_NAME}
                data={assetCreationAssetCountData}
                color={ASSET_CREATION_HEXA_COLOR}
                yLabel={'Assets'}
              />
            </div>
          </div>
          <div style={{ display: 'flex', flexDirection: 'row', marginTop: 10, marginLeft: 20, alignItems: 'center' }}>
            <div style={{ flex: '0 0 50%' }}>
              <StackedBarChart
                title='PaaS Asset Creation'
                task={PAAS_ASSET_CREATION_PROCESS_TASK_NAME}
                data={paasAssetCreationData}
                color={PAAS_ASSET_CREATION_HEXA_COLOR}
                yLabel={'Orders'}
              />
            </div>
            <div style={{ flex: '0 0 50%' }}>
              <StackedBarChart
                title='Asset Warranty'
                task={ASSET_WARRANTY_PROCESS_TASK_NAME}
                data={assetWarrantyData}
                color={ASSET_WARRANTY_HEXA_COLOR}
                yLabel={'Orders'}
              />
            </div>
          </div>
          <div style={{ display: 'flex', flexDirection: 'row', marginTop: 10, marginLeft: 20, alignItems: 'center' }}>
            <div style={{ flex: '0 0 50%' }}>
              <StackedBarChart
                title='Contract Activation'
                task={ACTIVATE_CONTRACT_PROCESS_TASK_NAME}
                data={contractActivationData}
                color={CONTRACT_ACTIVATION_HEXA_COLOR}
                yLabel={'Contracts'}
              />
            </div>
            <div style={{ flex: '0 0 50%' }}>
              <StackedBarChart
                title='Part Mapping Creation'
                task={PART_MAPPING_CREATION_PROCESS_TASK_NAME}
                data={partMappingCreationData}
                color={PART_MAPPING_CREATION_PROCESS_HEXA_COLOR}
                yLabel={'Executions'}
              />
            </div>
          </div>
          <div style={{ display: 'flex', flexDirection: 'row', marginTop: 10, marginLeft: 20, alignItems: 'center' }}>
            <div style={{ flex: '0 0 50%' }}>
              <StackedBarChart
                title='Asset Update'
                task={ASSET_UPDATE_PROCESS_TASK_NAME}
                data={assetUpdateData}
                color={ASSET_UPDATE_PROCESS_HEXA_COLOR}
                yLabel={'Assets'}
              />
            </div>
            <div style={{ flex: '0 0 50%' }}>
              <StackedBarChart
                title='Part Mapping Update'
                task={PART_MAPPING_UPDATE_PROCESS_TASK_NAME}
                data={partMappingUpdateData}
                color={PART_MAPPING_UPDATE_PROCESS_HEXA_COLOR}
                yLabel={'Part Numbers'}
              />
            </div>
          </div>
          <div style={{ display: 'flex', flexDirection: 'row', marginTop: 10, marginLeft: 20, alignItems: 'center' }}>
            <div style={{ flex: '0 0 50%' }}>
              <StackedBarChart
                title='Asset Geneology Creation'
                task={ASSET_GENEOLOGY_CREATION_TASK_NAME}
                data={assetGeneologyCreationData}
                color={ASSET_GENEOLOGY_CREATION_TASK_NAME}
                yLabel={'Chasis'}
              />
            </div>
          </div>
        </div>
        <div>
          <Dialog
            open={datePickerOpen}
            aria-labelledby='alert-dialog-title'
            aria-describedby='alert-dialog-description'
          >
            <DateRangePicker
              initialDateRange={dateRange}
              definedRanges={[]}
              open={datePickerOpen}
              minDate={threeMonthAgo}
              maxDate={new Date()}
              toggle={() => setDatePickerOpen(!datePickerOpen)}
              onChange={(range: DateRange) => {
                const updatedSearchRequest = {
                  ...searchRequest,
                };
                if (range.startDate?.getTime() === range.endDate?.getTime()) {
                  const dayInMs = 23.5 * 3600 * 1000;
                  range.endDate?.setTime(range.endDate?.getTime() + dayInMs);
                }
                const from = moment(range!.startDate).utc().startOf('day');
                const to = moment(range!.endDate).utc().endOf('day');

                updatedSearchRequest['dates'] = {
                  from: from.toISOString(),
                  to: to.toISOString(),
                };
                setSearchRequest(updatedSearchRequest);
                setDateRange(range);
              }}
            />
            <div className={classes.datePickerDialogButtonContainer}>
              <Button
                variant='contained'
                color='primary'
                onClick={() => {
                  setDatePickerOpen(false);
                  refreshDashboardData(searchRequest);
                }}
              >
                Apply
              </Button>
            </div>
          </Dialog>
        </div>
      </ShowIf>
    </React.Fragment>
  );
};

// function ExecutionsPieChart(props: { data: any[][] }) {
//     const { data } = props;
//     const RADIAN = Math.PI / 180;
//     const renderCustomizedLabel = (props: any) => {
//         console.log(props);
//         const { cx, cy, midAngle, innerRadius, outerRadius, percent, index, value } = props;
//         const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
//         const x = cx + radius * Math.cos(-midAngle * RADIAN);
//         const y = cy + radius * Math.sin(-midAngle * RADIAN);
//         if (value > 0) {
//             return (
//                 <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
//                     {value}
//                 </text>
//             );
//         } else return <div />
//     };

//     return (
//         <div style={{ display: 'flex', flexDirection: 'column', marginTop: 10, marginLeft: 20, alignItems: 'center' }}>
//             <div style={{ flex: '0 0 60px', alignItems: 'center' }} >
//                 <h3>Errors + Warnings</h3>
//             </div>
//             <div style={{ flex: '0 0 405px', alignItems: 'center' }} >
//                 <ShowIf condition={data[1].length > 0}>
//                     <PieChart width={500} height={400}>
//                         <Tooltip />
//                         <Legend verticalAlign="top" width={250} height={36} align={'left'} />
//                         <Pie label isAnimationActive={false} data={data[0]} dataKey="value" cx="50%" cy="50%" outerRadius={100} fill="#8884d8" >
//                             {/*<LabelList dataKey="value" fill="#fff" textAnchor="middle" dominantBaseline="middle" content={ConditionalLabel}/>*/}
//                         </Pie>
//                         {/*<Pie label isAnimationActive={false} data={data[1]} dataKey="value" cx="50%" cy="50%" legendType={'none'} innerRadius={110} outerRadius={130} fill="#82ca9d">*/}
//                         {/*    /!*<LabelList dataKey="value" fill="white" stroke={'black'} textAnchor="middle" dominantBaseline="middle" position={'outside'}/>*!/*/}
//                         {/*</Pie>*/}
//                     </PieChart>
//                 </ShowIf>
//                 <ShowIf condition={data[1].length === 0}>
//                     <NoData />
//                 </ShowIf>
//             </div>
//         </div>
//     );
// }

const NoData = (props: any) => {
  return (
    <div className='visualization-error' style={{ padding: '10px', paddingBottom: '30px' }}>
      No data available.
    </div>
  );
};

const getPercentage = (value: number, total: number) => {
  return value ? Math.round((value / total) * 100 * 100) / 100 : value;
};

const convertToPercentage = (_data: StackedBarChartData[]): StackedBarChartData[] => {
  const data = _data.map(({ value, critical, error, success, warning, ...rest }) => {
    const _value = critical + error + success + warning;
    return {
      critical: getPercentage(critical, _value),
      error: getPercentage(error, _value),
      success: getPercentage(success, _value),
      warning: getPercentage(warning, _value),
      value: _value,
      ...rest,
    };
  });
  return data;
};

function StackedBarChart(props: {
  title: string;
  task: string;
  data: StackedBarChartData[];
  color: string;
  yLabel: string;
  showPercentageValues?: boolean;
}) {
  const { title, task, data, yLabel, showPercentageValues } = props;
  const classes = useStyles();
  // const CustomizedMostPopularLabel = (props: any) => {
  //     console.log(props);
  //     const { x, y, width, height, value } = props;
  //     const radius = 15;
  //     if (data.length < 8) {
  //         return (
  //             <g>
  //                 {/*<circle cx={x} cy={y - 20} r={4} fill={'#FAD74F'} />*/}
  //                 {/*<text x={x + width / 2} y={y - 20} textAnchor="middle" dominantBaseline="middle" fill={'#FAD74F'}>*/}
  //                 {/*  {value}*/}
  //                 {/*</text>*/}
  //                 {/*  <circle cx={x} cy={y - 40} r={4} fill={'#7BBE78'} />*/}
  //                 {/* <text x={x + width / 2} y={y - 40} textAnchor="middle" dominantBaseline="middle" fill={'#7BBE78'}>*/}
  //                 {/*  {value}*/}
  //                 {/*</text>*/}
  //                 {/*  <circle cx={x} cy={y - 60} r={4} fill={'#F5716A'} />*/}
  //                 {/*<text x={x + width / 2} y={y - 60} textAnchor="middle" dominantBaseline="middle" fill={'#F5716A'}>*/}
  //                 {/*  {value}*/}
  //                 {/*</text>*/}
  //                 <circle cx={x + width / 2} cy={y - radius} r={radius} fill={color} />
  //                 {/*<square cx={x + width / 2} cy={y - radius} r={radius} fill={color} />*/}
  //                 <text x={x + width / 2} y={y - radius} fill="#fff" textAnchor="middle" dominantBaseline="middle">
  //                     {value}
  //                 </text>
  //             </g>

  //         );
  //     } else return <div />
  // }

  const ConditionalLabel = (props: any) => {
    const { x, y, width, height, value } = props;
    if (data.length < 8 && value > 0) {
      return (
        <g>
          <text x={x + Math.floor((width - value.toString().length * 7) / 2)} y={y + height / 2 + 5} fontSize='14'>
            {value}
          </text>
        </g>
      );
    } else return <div />;
  };

  const _data = data.map(({ critical, error, ...rest }) => ({
    critical: 0,
    error: error + critical,
    ...rest,
  }));

  const dataToRender: StackedBarChartData[] = useMemo(
    () => (showPercentageValues ? convertToPercentage(_data) : _data),
    [_data, showPercentageValues],
  );

  const handleBarClick = (data: any, status: ExecutionStatus, taskType: string) => {
    window.open(`/executions?date=${data.payload.fullDate}&status=${status}&type=${taskType}`);
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', marginTop: 10, marginLeft: 20, alignItems: 'center' }}>
      <div style={{ flex: '0 0 60px', alignItems: 'center' }}>
        <h3>{title}</h3>
      </div>
      <div style={{ flex: '0 0 405px', alignItems: 'center' }}>
        <ShowIf condition={dataToRender.length > 0}>
          <BarChart
            width={500}
            height={400}
            data={dataToRender}
            margin={{
              top: 30,
              right: 30,
              left: 20,
              bottom: 10,
            }}
          >
            <CartesianGrid strokeDasharray='4 4' color={'#F5716A'} floodColor={'#F5716A'} />
            <XAxis dataKey='name' label={{ value: 'Days', angle: 0, offset: -5, position: 'insideBottom' }} />
            <YAxis
              label={{ value: yLabel, angle: -90, position: 'insideLeft' }}
              yAxisId='left'
              orientation='left'
              tickFormatter={showPercentageValues ? tick => `${tick}%` : void 0}
            />
            <Tooltip
              itemSorter={(a: any) => {
                return a.name;
              }}
            />
            <Bar
              isAnimationActive={false}
              yAxisId='left'
              dataKey='success'
              stackId='a'
              fill='#7BBE78'
              onClick={data => handleBarClick(data, ExecutionStatus.SUCCESS, task)}
              className={classes.bar}
            >
              <LabelList dataKey='success' content={ConditionalLabel} />
              {/*<LabelList dataKey="value" content={CustomizedMostPopularLabel} />*/}
            </Bar>
            <Bar
              isAnimationActive={false}
              yAxisId='left'
              dataKey='warning'
              stackId='a'
              fill='#FAD74F'
              onClick={data => handleBarClick(data, ExecutionStatus.WARN, task)}
              className={classes.bar}
            >
              <LabelList dataKey='warning' content={ConditionalLabel} />
            </Bar>
            <Bar
              isAnimationActive={false}
              yAxisId='left'
              dataKey='error'
              stackId='a'
              fill='#F5716A'
              onClick={data => handleBarClick(data, ExecutionStatus.ERROR, task)}
              className={classes.bar}
            >
              <LabelList dataKey='error' content={ConditionalLabel} />
            </Bar>
          </BarChart>
        </ShowIf>
        <ShowIf condition={dataToRender.length === 0}>
          <NoData />
        </ShowIf>
      </div>
    </div>
  );
}
