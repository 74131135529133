import React, { useContext } from 'react';
import { UserInfo } from '../services/User';
import UserInfoContext from './UserInfoContext';
import { Redirect } from 'react-router-dom';

export function RestrictedRoute(props: any) {
  const userInfo = useContext<UserInfo>(UserInfoContext);
  if (!props.roles.find((element: string) => element === userInfo.role, false)) return <Redirect to='/executions' />;
  return props.children;
}
