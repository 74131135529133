import React from 'react';
import './App.css';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core';
import { Provider } from 'react-redux';
import { Store } from './common/Store';
import SecuredApp from './SecuredApp';

import moment from 'moment-timezone';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#fe5000',
    },
    secondary: {
      main: '#e9b533',
    },
  },
});

moment.tz.setDefault('America/Los_Angeles');

export default () => {
  return (
    <MuiThemeProvider theme={theme}>
      <Provider store={Store}>
        <SecuredApp />
      </Provider>
    </MuiThemeProvider>
  );
};
