import { configureStore, createSlice } from '@reduxjs/toolkit';
import { StoreState } from './StoreState';

const initialState: StoreState = {
  snackbar: { open: false },
  userEmail: undefined,
  fullName: undefined,
};

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    openSnackbar: (state: StoreState, action: any) => {
      state.snackbar = { ...action.payload, open: true };
    },
    closeSnackbar: (state: StoreState) => {
      state.snackbar = { ...state.snackbar, open: false };
    },
    setUserEmail: (state: StoreState, action: any) => {
      state.userEmail = action.payload;
    },
    setFullName: (state: StoreState, action: any) => {
        state.fullName = action.payload;
    },
  },
});

const Store = configureStore({ reducer: appSlice.reducer });
export { Store };
export const { openSnackbar, closeSnackbar, setUserEmail, setFullName } = appSlice.actions;
