import {
  Box,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
} from "@material-ui/core";
import React, { useState } from "react";

import Checkbox from "@material-ui/core/Checkbox";
import { ChooseTime } from "./ChooseTime";

export function Weekly(props: any) {
  const [value] = useState(props.value);

  const onAtHourChange = (e: any) => {
    let val = value;

    val[1] = `${e.target.value}`;
    props.onChange(val);
  };
  const onAtMinuteChange = (e: any) => {
    let val = value;

    val[0] = `${e.target.value}`;
    props.onChange(val);
  };

  const onCheck = (e: any) => {
    let val = value;

    if (e.target.checked) {
      val[1] = `${val[1]}`.split("/").length > 1 ? "0" : val[1].toString();
      val[2] = "?";
      val[3] = "*";
      if (val[4] === "*" || val[4] === "?" || val[4] === "MON-FRI") {
        val[4] = e.target.value;
      } else {
        val[4] = val[4] + "!" + e.target.value;
      }
    } else {
      val[4] = val[4].split("!");
      if (val[4].length > 1) {
        val[4].splice(val[4].indexOf(e.target.value), 1);
        val[4] = val[4].toString().replace(/,/g, "!");
      } else {
        val[4] = "*";
      }
    }

    props.onChange(val);
  };

  return (
    <div>
      <Box>
        <FormControl component="fieldset">
          <FormGroup row={true}>
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  value="MON"
                  onChange={onCheck}
                  checked={value[4].search("MON") !== -1 ? true : false}
                />
              }
              label="Monday"
            />
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  value="TUE"
                  onChange={onCheck}
                  checked={value[4].search("TUE") !== -1 ? true : false}
                />
              }
              label="Tuesday"
            />
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  value="WED"
                  onChange={onCheck}
                  checked={value[4].search("WED") !== -1 ? true : false}
                />
              }
              label="Wednesday"
            />
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  value="THU"
                  onChange={onCheck}
                  checked={value[4].search("THU") !== -1 ? true : false}
                />
              }
              label="Thursday"
            />
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  value="FRI"
                  onChange={onCheck}
                  checked={value[4].search("FRI") !== -1 ? true : false}
                />
              }
              label="Friday"
            />
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  value="SAT"
                  onChange={onCheck}
                  checked={value[4].search("SAT") !== -1 ? true : false}
                />
              }
              label="Saturday"
            />
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  value="SUN"
                  onChange={onCheck}
                  checked={value[4].search("SUN") !== -1 ? true : false}
                />
              }
              label="Sunday"
            />
          </FormGroup>
        </FormControl>
      </Box>

      <Box>
        <FormLabel>Start time</FormLabel>
        <ChooseTime
          hour={value[1]}
          minute={value[0]}
          onAtMinuteChange={onAtMinuteChange}
          onAtHourChange={onAtHourChange}
        />
      </Box>
    </div>
  );
}
