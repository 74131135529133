import React, { useEffect } from 'react';
import './App.css';
import { Container } from '@material-ui/core';
import NavBar from './components/NavBar';
import { Redirect, Route, Switch } from 'react-router-dom';
import Executions from './pages/Executions/Executions';
import Dashboard from './pages/Dashboard/Dashboard';
import Snackbar from './components/Snackbar';
import { setFullName, setUserEmail, Store } from './common/Store';
import ExecutionDetails from './pages/ExecutionDetails/ExecutionDetails';
import { UserManagement } from './pages/UserManagement/UserManagement';
import { UserContainer } from './components/UserContainer';
import { RestrictedRoute } from './components/RestrictedRoute';
import { UserRoleType } from './services/User';
import { makeStyles } from '@material-ui/core/styles';
import Tasks from './pages/Tasks/Tasks';
import { Logout } from './pages/Logout';
import { useAuth0 } from '@auth0/auth0-react';
import ProtectedRoute from './components/ProtectedRoute';

const useStyles = makeStyles({
  contentContainer: {
    margin: '30px 60px 30px 60px',
  },
});

export default () => {
  const { isLoading, isAuthenticated, user } = useAuth0();
  const classes = useStyles();
  useEffect(() => {
    if (!isLoading && isAuthenticated && user) {
      Store.dispatch(setFullName(user?.name));
      Store.dispatch(setUserEmail(user?.email));
    }
  }, [user, isLoading, isAuthenticated]);

  return (
    <UserContainer>
      <Snackbar />
      <NavBar />
      <Container maxWidth={false}>
        <div className={classes.contentContainer}>
          <Switch>
            <Route path={'/logout'} component={Logout} />
            <ProtectedRoute exact path='/dashboard' component={Dashboard} />
            <Route exact path='/'>
              <Redirect to='/executions' />
            </Route>
            <ProtectedRoute exact path='/executions' component={Executions} />
            <ProtectedRoute path='/tasks' component={Tasks} />
            <ProtectedRoute path='/executions/:id' component={ExecutionDetails} />
            <ProtectedRoute
              path='/users'
              component={() => (
                <RestrictedRoute roles={[UserRoleType.Admin]}>
                  <UserManagement />
                </RestrictedRoute>
              )}
            />
          </Switch>
        </div>
      </Container>
    </UserContainer>
  );
};
