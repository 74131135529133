import React, { useEffect } from 'react';
import { Store, closeSnackbar } from '../common/Store';

import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import MuiAlert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import { SnackbarData, SnackbarSeverity } from './SnackbarData';

const useStyles = makeStyles({
  cookieAlert: {
    '& .MuiAlert-icon': {
      paddingTop: '12px',
    },
  },
});
const Alert = (props: any) => {
  const classes = useStyles();

  return <MuiAlert className={classes.cookieAlert} elevation={6} variant='filled' {...props} />;
};

export default () => {
  const data: SnackbarData = useSelector((state: any) => state.snackbar);

  useEffect(() => {}, [data]);

  const handleCloseSnackBar = () => {
    Store.dispatch(closeSnackbar());
  };

  return (
    <Snackbar
      open={data.open}
      autoHideDuration={data.disableAutohide ? null : 5000}
      onClose={handleCloseSnackBar}
      action={
        <>
          <IconButton size='small' aria-label='close' color='inherit' onClick={handleCloseSnackBar}>
            <CloseIcon fontSize='small' />
          </IconButton>
        </>
      }
    >
      <Alert onClose={handleCloseSnackBar} severity={data.severity || SnackbarSeverity.INFO}>
        {data.message}
      </Alert>
    </Snackbar>
  );
};
