import { Box, FormGroup, FormLabel, Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";

import Autocomplete from "@material-ui/lab/Autocomplete";
import { ChooseTime } from "./ChooseTime";
import TextField from "@material-ui/core/TextField";

const Monthly = (props: any) => {
  const [value, setValue] = useState(props.value);

  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  const onAtHourChange = (e: any) => {
    let val = value;
    val[1] = `${e.target.value}`;
    props.onChange(val);
  };
  const onAtMinuteChange = (e: any) => {
    let val = value;
    val[0] = `${e.target.value}`;
    props.onChange(val);
  };
  const onMonthsChange = (months: any) => {
    let val = value;
    val[3] =
      months.length > 0
        ? months.map((e: number) => e.toString()).join("!")
        : "*";
    val[2] = val[2] || "*";

    props.onChange(val);
  };
  const onDaysChange = (days: any) => {
    let val = value;
    val[2] = days.length
      ? days.map((e: number) => e.toString()).join("!")
      : "*";
    val[3] = val[3] || "*";
    props.onChange(val);
  };
  return (
    <>
      <Box marginBottom={"1em"}>
        <Grid container spacing={2}>
          <Grid item xs={7}>
            <FormLabel>On Months</FormLabel>
            <Autocomplete
              multiple
              size="small"
              filterSelectedOptions
              defaultValue={
                value[3] && value[3] !== "*" ? value[3].split("!") : []
              }
              onChange={(event: any, value: any) => {
                onMonthsChange(value);
              }}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" />
              )}
              getOptionLabel={(option: string) => option}
              options={Array.from(Array(12).keys()).map((e) =>
                (e + 1).toString()
              )}
            />
          </Grid>
          <Grid item xs={5}>
            <FormLabel>On Days</FormLabel>
            <Autocomplete
              multiple
              size="small"
              filterSelectedOptions
              onChange={(event: any, value: any) => {
                onDaysChange(value);
              }}
              defaultValue={
                value[2] && value[2] !== "*" ? value[2].split("!") : []
              }
              renderInput={(params) => (
                <TextField {...params} variant="outlined" />
              )}
              getOptionLabel={(option: string) => option}
              options={Array.from(Array(31).keys()).map((e) =>
                (e + 1).toString()
              )}
            />
          </Grid>
        </Grid>
      </Box>
      <Box>
        <FormGroup>
          <FormLabel>Start time</FormLabel>
          <ChooseTime
            hour={value[1]}
            minute={value[0]}
            onAtMinuteChange={onAtMinuteChange}
            onAtHourChange={onAtHourChange}
          />
        </FormGroup>
      </Box>
    </>
  );
};

export default Monthly;
