export default {
  app: {
    apiUrl: process.env.REACT_APP_SSOT_API_URL,
    stage: process.env.REACT_APP_STAGE,
  },
  auth0: {
    domain: process.env.REACT_APP_AUTH0_DOMAIN as string,
    clientId: process.env.REACT_APP_AUTH0_CLIENTID as string,
    audience: process.env.REACT_APP_AUTH0_AUDIENCE,
    connection: 'SSOT',
  },
};
