import { LogExecution, ExecutionStatus } from './LogExecution';
import Config from '../config/Config';
import { axiosInstance } from './AxiosInstance';

const API_URL = Config.app.apiUrl;

export default abstract class executionApi {

  static getDashboardInfoByFilter = (filter: DashboardFilter): Promise<ExecutionDayInfo[]> => {
    return axiosInstance.post(`${API_URL}/v1/dashboard/by-filter`, filter);
  };

  static getCurrentQuarterInfo = (): Promise<QuarterInfo[]> => {
    return axiosInstance.get(`${API_URL}/v1/getQuarterDetails`);
  };

}

export interface ExecutionDayInfo {
  task: string;
  day: string;
  status: ExecutionStatus;
  value: number;
}
export interface QuarterInfo {
  Number: number;
  StartDate: string;
  EndDate: string
  FullyQualifiedLabel: string;
}


export interface DashboardFilter {
  dates: {
    from: string;
    to: string;
  };
}
