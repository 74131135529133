import { Box, FormControl, FormLabel } from "@material-ui/core";
import React, { useEffect, useState } from "react";

import { ChooseTime } from "./ChooseTime";
import TextField from "@material-ui/core/TextField";

export function Daily(props: any) {
  const [every, setEvery] = useState<boolean>(true);
  const [value, setValue] = useState(props.value);
  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  useEffect(() => {
    const value = props.value;
    if (value[2] === "?") {
      setEvery(false);
    } else {
      setEvery(true);
    }
  }, [props.value]);

  const onDayChange = (e: any) => {
    if ((e.target.value > 0 && e.target.value < 32) || e.target.value === "") {
      let val = [
        value[0] === "*" ? "0" : value[0],
        value[1] === "*" ? "0" : value[1],
        "*",
        "*",
        "?",
        "*",
      ];
      if (e.target.value === "") {
        val[2] = "";
      } else {
        val[2] = `1/${e.target.value}`;
      }
      props.onChange(val);
    }
  };
  const onAtHourChange = (e: any) => {
    let val = value;
    val[1] = `${e.target.value}`;
    props.onChange(val);
  };
  const onAtMinuteChange = (e: any) => {
    let val = value;
    val[0] = `${e.target.value}`;
    props.onChange(val);
  };

  return (
    <div>
      <Box>
        <FormControl>
          <FormLabel>Every day(s)</FormLabel>
          <TextField
            id="outlined-number"
            value={value[2].split("/")[1] ? value[2].split("/")[1] : ""}
            onChange={onDayChange}
            type="number"
            inputProps={{ min: 1 }}
            InputLabelProps={{
              disabled: every ? false : true,
              shrink: true,
            }}
            margin="dense"
            variant="outlined"
          />
        </FormControl>
      </Box>

      <Box>
        <FormLabel>Start time</FormLabel>
        <ChooseTime
          hour={value[1]}
          minute={value[0]}
          onAtMinuteChange={onAtMinuteChange}
          onAtHourChange={onAtHourChange}
        />
      </Box>
    </div>
  );
}
