import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { CustomCron } from '../../components/StyledCron/CustomCron';
import { CircularProgress, withStyles } from '@material-ui/core';

const StyledButton = withStyles({
    root: {
        marginRight: '5px',
        marginLeft: '5px',
    },
})(Button);

export default function TasksScheduleUpdateDialog(props: {
    open: boolean;
    isUpdating: boolean,
    scheduleExpression: string;
    onScheduleChanged: Function;
    onClose: Function;
}) {
    const { open, isUpdating, onClose, onScheduleChanged, scheduleExpression } = props;
    const [ value, setValue ] = React.useState(scheduleExpression);

    const handleClose = () => {
        onClose();
    };

    const handleScheduleChanged = (e: any) => {
        setValue(e)
    };

    const handleUpdateScheduleChanged = () => {
        onScheduleChanged(value);
    };


    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Choose Schedule</DialogTitle>
                <DialogContent>
                    <CustomCron onChange={handleScheduleChanged}
                        value={value}
                    />
                </DialogContent>
                <DialogActions>
                    <div
                        style={{
                            justifyContent: 'flex-end',
                            display: 'flex',
                        }}
                    >
                        <StyledButton
                            color='secondary'
                            variant='outlined'
                            onClick={handleClose}
                        >
                            Cancel
                        </StyledButton>

                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            <div
                                style={{
                                    position: 'relative',
                                }}
                            >
                                <Button
                                    variant='contained'
                                    color='primary'
                                    autoFocus
                                    disabled={isUpdating}
                                    onClick={handleUpdateScheduleChanged}
                                >
                                    Save
                                </Button>
                                {isUpdating && (
                                    <CircularProgress
                                        size={24}
                                        style={{
                                            position: 'absolute',
                                            top: '50%',
                                            left: '50%',
                                            marginTop: -12,
                                            marginLeft: -12,
                                        }}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </DialogActions>
            </Dialog>
        </div>
    );
}
