import React, { CSSProperties } from 'react';
import Paper from '@material-ui/core/Paper';
import { LogExecutionDetail } from '../../services/LogExecution';
import { StatusIcon } from '../../common/StatusIcon';
import MaterialTable, { Column } from 'material-table';

function MessageCell(props: { row: LogExecutionDetail }) {
    const { row } = props;
    const messages = row.message != undefined ? row.message.split(";") : [];
    return (<div key={row.id}>
        { messages.map(function(message){
                return <li>{message}</li>
        }) } </div>
    );
}

export default function ExecutionDetailsTable(props: { data: LogExecutionDetail[] }) {
    const rows = props.data;
    const cellStyle: CSSProperties = {
        textAlign: 'center',
        paddingTop: '4px',
        paddingBottom: '4px',
        wordWrap: 'break-word',
    };

    const columns:  Column<LogExecutionDetail>[] = [
        { title: 'Entity', field: 'entity',  defaultSort: 'desc', cellStyle: cellStyle  },
        { title: 'Reference', field: 'reference', cellStyle: cellStyle },
        { title: 'Status', field: 'status', cellStyle: { width: '5%', paddingTop: '4px', paddingBottom: '4px', wordWrap: 'break-word' }, render: (row) => <StatusIcon value={row.category}/>, customSort: (a, b) => a.category.localeCompare(b.category)},
        { title: 'Message', sorting: false,  field: 'message', cellStyle: { width: '50%', paddingTop: '4px', paddingBottom: '4px', wordWrap: 'break-word' }, render: (row) => <MessageCell row={row} />},
    ];
    return (
        <Paper>
           <MaterialTable
               columns={columns}
               data={rows}
               components={{
                   Toolbar: () => null,
               }}
               options={{
                   showTitle: false,
                   search: false,
                   pageSizeOptions: [5, 10, 25],
                   pageSize: 25,
                   emptyRowsWhenPaging: false,
                   headerStyle: {
                       fontWeight: 'bolder',
                       textAlign: 'center',
                       paddingTop: '4px',
                       paddingBottom: '4px',
                   },
               }}
           />

        </Paper>
    );
}
