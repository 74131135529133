import React, { useEffect, useState } from 'react';
import { Box } from '@material-ui/core';
import TaskApi, { Task } from '../../services/TaskApi';
import TasksTable from './TasksTable';
import { openSnackbar, Store } from '../../common/Store';
import { SnackbarSeverity } from '../../components/SnackbarData';
import ColoredLine from '../../components/ColoredLine';

export default () => {
  const [tasks, setTasks] = useState<Task[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isUpdating, setIsUpdating] = useState(false);

  useEffect(() => {
    getTasks();
  }, []);

  const handleUpdateTaskDefinition = (task: Task, callBack: Function) => {
    updateTask(task, callBack);
  };

  const handleTaskExecute = () => {
    getTasks();
  };

  return (
    <React.Fragment>
      <Box mb={'18px'}>
        <h1>Tasks</h1>
      </Box>
      <ColoredLine />
      <br />
      <TasksTable
        isLoading={isLoading}
        isUpdating={isUpdating}
        onTaskDefinitionChange={handleUpdateTaskDefinition}
        onTaskExecute={handleTaskExecute}
        rows={tasks}
      />
    </React.Fragment>
  );

  function getTasks() {
    setIsLoading(true);
    TaskApi.getTasks()
      .then(tasks => {
        setTasks(tasks);
      })
      .finally(() => setIsLoading(false));
  }

  function updateTask(task: Task, callBack: Function | undefined = undefined) {
    setIsUpdating(true);
    TaskApi.updateTaskType(task.id, task)
      .then(() => {
        if (callBack != undefined) {
          callBack();
        }
      })
      .finally(() => {
        setIsUpdating(false);
        Store.dispatch(
          openSnackbar({
            message: `Task (${task.state}) ${task.name} was updated.`,
            severity: task.state == 'ENABLED' ? SnackbarSeverity.SUCCESS : SnackbarSeverity.INFO,
          }),
        );
      });
  }
};
