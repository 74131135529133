import "./UserMenu.scss";

import {
  Box,
  Button,
  ClickAwayListener,
  Grow,
  List,
  ListItem,
  Paper,
  Popper,
  Typography,
} from "@material-ui/core";
import React, { useContext, useState } from "react";



import UserInfoContext from "./UserInfoContext";
import { LogoutButton } from '../common/LogoutButton';
import { UserInfo } from '../services/User';
import { useSelector } from 'react-redux';

export function UserMenu(props: any) {
  const fullName: any = useSelector((state: any) => state.fullName);
  const userInfo = useContext<UserInfo>(UserInfoContext);
  const [userMenu, setUserMenu] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div className="user-menu">
      <Button
        color="inherit"
        aria-label="open drawer"
        onClick={(event: any) => {
          setUserMenu(event.currentTarget);
          setIsOpen(true);
        }}
      >
        <Typography variant="caption" color="inherit">
          {userInfo.username}
        </Typography>
      </Button>
      {userInfo && isOpen ? (
        <ClickAwayListener
          onClickAway={() => {
            setIsOpen(false);
            setUserMenu(null);
          }}
        >
          <Popper
            id="user-menu-popper"
            anchorEl={userMenu}
            keepMounted
            open={isOpen}
            placement="bottom"
            transition
          >
            {({ TransitionProps }) => (
              <Grow {...TransitionProps}>
                <Paper>
                  <Box justifyContent="center">
                    <List dense>
                      <ListItem divider>
                        <Typography>{fullName}</Typography>
                      </ListItem>
                      <ListItem>
                        <Typography variant="body2">
                          Organization Role:
                        </Typography>
                      </ListItem>
                      <ListItem divider>
                        <Typography variant="body2" color="textSecondary">
                          {userInfo?.role}
                        </Typography>
                      </ListItem>
                      <ListItem button>
                        <LogoutButton></LogoutButton>
                      </ListItem>
                    </List>
                  </Box>
                </Paper>
              </Grow>
            )}
          </Popper>
        </ClickAwayListener>
      ) : null}
    </div>
  );
}
