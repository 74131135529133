export type SnackbarData = {
  message: string;
  open: boolean;
  severity?: SnackbarSeverity;
  disableAutohide?: boolean;
};

export enum SnackbarSeverity {
  SUCCESS = 'success',
  ERROR = 'error',
  WARNING = 'warning',
  INFO = 'info',
}
