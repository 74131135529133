// import Grid from "@material-ui/core/Grid";
// import Daily from "@dealmeddevs/react-cron-generator";
// import Monthly from "@dealmeddevs/react-cron-generator";
import { Box, Button, ButtonGroup, Paper, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";

import { Daily } from "./Daily";
import { Hourly } from "./Hourly";
import Monthly from "./Monthly";
import { StyledFormControl } from "./FormComponents";
import { Weekly } from "./Weekly";

const tabs = ["Hourly", "Days", "Weekdays", "Months"];

interface CronProps {
  onChange: any;
  value?: string;
  defaultValue?: string;
}

export function CustomCron(props: CronProps) {
  const [value, setValue] = useState<string[]>();
  const [selectedTab, setSelectedTab] = useState("Days");
  const [, setSelectedTabValue] = useState(1);

  const defaultValue = (tab: any) => {
    if (props.defaultValue) {
      let val = props.defaultValue.replace(/,/g, "!").split(" ");
      let defaultTab;
      setValue(val);
      defaultTab = tabs[0];
      if (val[1].split("/").length > 1 || val[1] === "*") {
        defaultTab = tabs[0];
      } else if (val[2].startsWith("1/")) {
         defaultTab = tabs[1];
      } else if (val[2] === "?") {
         defaultTab = tabs[2];
      } else if (val[2].split("!")[0] || val[2].split("!")[0]) {
         defaultTab = tabs[3];
      }
      if (defaultTab === tab) return val;
    }
    switch (tab) {
      case tabs[0]:
        return ["0", "*", "*", "*", "?", "*"];
      case tabs[1]:
        return ["0", "0", "1/1", "*", "?", "*"];
      case tabs[2]:
        return ["0", "0", "?", "*", "*", "*"];
      case tabs[3]:
        return ["0", "0", "*", "*", "?", "*"];
      default:
        return ["0", "0", "1/1", "*", "?", "*"];
    }
  };
  const parentChange = (val: any) => {
    let newVal = "";
    newVal = val.toString().replace(/,/g, " ");
    newVal = newVal.replace(/!/g, ",");
    props.onChange(newVal);
  };

  const tabChanged = (tab: string, index: number) => {
    setSelectedTab(tab);
    setSelectedTabValue(index);
    const defaultVal = defaultValue(tab);
    setValue(defaultVal);
    parentChange(defaultVal);
  };

  const getHeaders = () => {
    // const TabPanel = (props) => {
    //     const { children, value, index, ...other } = props
    //     return (
    //         <Typography
    //             component="div"
    //             role="tabpanel"
    //             hidden={value !== index}
    //             id={`simple-tabpanel-${index}`}
    //             aria-labelledby={`simple-tab-${index}`}
    //             {...other}
    //         >
    //             <Box p={3}>{children}</Box>
    //         </Typography>
    //     )
    // }

    return (
      <>
        <ButtonGroup aria-label="small outlined button group">
          {tabs.map((tab: string, index: number) => (
            <Button
              variant="outlined"
              key={index}
              onClick={() => tabChanged(tab, index)}
              color={selectedTab === tab ? "primary" : "default"}
            >
              {tab}
            </Button>
          ))}
        </ButtonGroup>
      </>
    );
  };

  const onValueChange = (val: any) => {
    if (val && val.length) {
      setValue(val);
    } else {
      setValue(["0", "0", "1/1", "*", "?", "*"]);
      val = ["0", "0", "1/1", "*", "?", "*"];
    }
    parentChange(val);
  };

  // const getVal = () => {
  //   let val = cronstrue.toString(
  //     value.toString().replace(/,/g, " ").replace(/!/g, ",")
  //   );
  //   if (val.search("undefined") === -1) {
  //     return val;
  //   }
  //   return "-";
  // };

  const getComponent = (tab: any) => {
    switch (tab) {
      case tabs[0]:
        return <Hourly value={value} onChange={onValueChange} />;
      case tabs[1]:
        return <Daily value={value} onChange={onValueChange} />;
      case tabs[2]:
        return <Weekly value={value} onChange={onValueChange} />;
      case tabs[3]:
        return <Monthly value={value} onChange={onValueChange} />;

      default:
        return null;
    }
  };
  useEffect(() => {
    if (props.value) {
      let val = props.value.replace(/,/g, "!").split(" ");
      setValue(val);
      if (val[1].split("/").length > 1 || val[1] === "*") {
        setSelectedTab(tabs[0]);
        setSelectedTabValue(0);
      } else if (val[2].startsWith("1/") || val[2].startsWith("0/")) {
        setSelectedTab(tabs[1]);
        setSelectedTabValue(1);
      } else if (val[2] === "?") {
        setSelectedTab(tabs[2]);
        setSelectedTabValue(2);
      } else {
        setSelectedTab(tabs[3]);
        setSelectedTabValue(3);
      }
    } else {
      setValue(defaultValue(selectedTab));
    }
    if (value) parentChange(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      {value ? (
        <StyledFormControl margin="dense">
          <Box marginBottom={"1em"}>
            <Box justifyContent="flex-start">
              <Typography>Repeat On:</Typography>
              {getHeaders()}
            </Box>
          </Box>
          <Paper variant="outlined">
            <Box p={1}>
              <>{getComponent(selectedTab)}</>
            </Box>
          </Paper>
        </StyledFormControl>
      ) : null}
    </>
  );
}
