import Config from '../config/Config';
import { UserRoleType, UserType } from './User';
import { axiosInstance } from './AxiosInstance';

const API_URL = Config.app.apiUrl;

export default abstract class UserApi {

  static getInfo(): Promise<any> {
    return axiosInstance.get(`${API_URL}/v1/users/me`);
  }

  static getUsers(): Promise<UserType[]> {
    return axiosInstance.get(`${API_URL}/v1/users`);
  }

  static updateUser(username: string, role: UserRoleType): Promise<any> {
    return axiosInstance.put(`${API_URL}/v1/users`, { username, role });
  }
}
