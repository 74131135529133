export interface LogExecutionDetail {
  id: string;
  entity: string;
  executionId: string;
  reference: string;
  category: string;
  message: string;
}

export interface LogExecution {
  id: string;
  label: string;
  task: string;
  message: string;
  status: string;
  parentId?: string;
  startedAt: string;
  endedAt: string;
  externalRef: string;
  username?: string;
  context?: LogExecution[];
  executions?: LogExecution[];
  type: string;
  details?: LogExecutionDetail[];
}

export enum ExecutionStatus {
  IN_PROGRESS = 'IN_PROGRESS',
  WARN = 'WARN',
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
  CRITICAL = 'CRITICAL',
  RETRY = 'RETRY',
}
