export interface UserType {
  username: string;
  createdOn: string;
  updatedOn: string;
  updatedBy: string;
  role: UserRoleType;
}
export interface UserInfo {
  role: UserRoleType;
  username?: string;
  name?: string;
}

export enum UserRoleType {
  Biz = 'Biz',
  Admin = 'Admin',
}
