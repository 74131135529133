import React, { CSSProperties } from 'react';
import Paper from '@material-ui/core/Paper';
import { LogExecution } from '../../services/LogExecution';
import { StatusIcon } from '../../common/StatusIcon';
import MaterialTable, { Column } from 'material-table';
import moment from 'moment';
import { Link } from 'react-router-dom';

function formatDate(date: string) {
  if (date) {
    return moment(date, 'YYYY-MM-DD[T]HH:mm:ss.SSS[Z]').format('M/D/YYYY h:mm a');
  }
  return 'Never';
}

export default function PastExecutionsTable(props: { currentExecutionId: string; data: LogExecution[] }) {
  const rows = props.data;
  const currentExecutionId = props.currentExecutionId;
  const cellStyle: CSSProperties = {
    textAlign: 'center',
    paddingTop: '4px',
    paddingBottom: '4px',
    wordWrap: 'break-word',
  };

  const backgroundStyle: CSSProperties = {
    backgroundColor: '#ffccbc85',
  };

  function getCellStyle(le: LogExecution, defaultStyle: CSSProperties): CSSProperties {
    if (le.id == currentExecutionId) {
      return { ...defaultStyle, ...backgroundStyle };
    } else return defaultStyle;
  }

  const columns: Column<LogExecution>[] = [
    {
      title: 'Id',
      field: 'id',
      defaultSort: 'desc',
      cellStyle: (e, rowData) => {
        return getCellStyle(rowData, cellStyle);
      },
      render: (row: LogExecution) => (
        <Link
          onClick={() => localStorage.setItem('ORDER_TITLE', row.label)}
          to={{ pathname: `/executions/${row.id}`, state: { fromDashboard: true } }}
          target='_blank'
        >
          {' '}
          {row.id}{' '}
        </Link>
      ),
    },
    {
      title: 'Execution Time',
      field: 'startedAt',
      cellStyle: (e, rowData) => {
        return getCellStyle(rowData, cellStyle);
      },
      render: row => formatDate(row.startedAt),
    },
    {
      title: 'Status',
      field: 'status',
      sorting: false,
      cellStyle: (e, rowData) => {
        return getCellStyle(rowData, cellStyle);
      },
      render: row => <StatusIcon value={row.status} />,
    },
    {
      title: 'Message',
      field: 'message',
      sorting: false,
      cellStyle: (e, rowData) => {
        return getCellStyle(rowData, { width: '40%' });
      },
    },
  ];
  return (
    <Paper>
      <MaterialTable
        columns={columns}
        data={rows}
        components={{
          Toolbar: () => null,
        }}
        options={{
          showTitle: false,
          search: false,
          paging: false,
          headerStyle: {
            fontWeight: 'bolder',
            textAlign: 'center',
            paddingTop: '4px',
            paddingBottom: '4px',
          },
          rowStyle: {
            textAlign: 'center',
            paddingTop: '4px',
            paddingBottom: '4px',
          },
        }}
      />
    </Paper>
  );
}
