import TaskApi, { Task } from "../../services/TaskApi";
import Paper from "@material-ui/core/Paper";
import ShowIf from "../../components/ShowIf";
import { CircularProgress, DialogContentText, IconButton, Switch, TextField, Tooltip, withStyles, TextareaAutosize, Container, FormGroup, FormControlLabel } from "@material-ui/core";
import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import cronstrue from "cronstrue";
import EditIcon from "@material-ui/icons/Edit";
import TasksScheduleUpdateDialog from "./TasksScheduleUpdateDialog";
import { StatusIcon } from "../../common/StatusIcon";
import moment from "moment";
import PlayCircleFilledSharpIcon from "@material-ui/icons/PlayCircleFilledSharp";
import { openSnackbar, Store } from "../../common/Store";
import { SnackbarSeverity } from "../../components/SnackbarData";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import UserInfoContext from "../../components/UserInfoContext";
import { UserRoleType } from "../../services/User";
import MaterialTable, { Column } from "material-table";
const ASSET_UPDATE = "Asset Update";


function LastExecution(props: { row: Task }) {
    const { row } = props;
    return (
        <div>
            <div>
                <ShowIf condition={row.lastExecution != undefined}>
                    <Tooltip title={`${row.lastStatus}: ${row.message || ""}`}>
                        <div>
                            {" "}
                            <StatusIcon value={row.lastStatus} toolTip={false} />
                            {moment(row.lastExecution, "YYYY-MM-DD[T]HH:mm:ss.SSS[Z]").format("M/D/YYYY h:mm a")}
                        </div>
                    </Tooltip>
                </ShowIf>
            </div>
        </div>
    );
}

function TaskEnabled(props: { row: Task, onTaskDefinitionChange: Function }) {
    const { row, onTaskDefinitionChange } = props;
    const [taskEnabled, setTaskEnabled] = React.useState("ENABLED" == row.state ? true : false);
    const userInfo = useContext(UserInfoContext);
    return (
        <Switch
            disabled={userInfo.role != UserRoleType.Admin}
            checked={taskEnabled}
            color="primary"
            onChange={(e: any, checked: boolean) => {
                setTaskEnabled(checked);
                row.state = checked ? "ENABLED" : "DISABLED";
                onTaskDefinitionChange({ id: row.id, name: row.name, state: row.state, schedule: row.schedule });
            }}
        />
    );
}

function TaskSchedule(props: { row: Task, isUpdating: boolean, onTaskDefinitionChange: Function }) {
    const { row, isUpdating, onTaskDefinitionChange } = props;
    const [open, setOpen] = React.useState(false);
    const [scheduleExpression, setScheduleExpression] = React.useState(getCronValue(row.schedule));
    const userInfo = useContext(UserInfoContext);




    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClickClose = () => {
        setOpen(false);
    };

    return (
        <div>
            {describeScheduleExpression(scheduleExpression)}
            <ShowIf condition={userInfo.role == UserRoleType.Admin}>
                <IconButton onClick={handleClickOpen}>
                    <EditIcon fontSize="small" />
                </IconButton>
            </ShowIf>
            <div style={{ display: "flex", flexDirection: "row" }}>
                <TasksScheduleUpdateDialog
                    open={open}
                    isUpdating={isUpdating}
                    onClose={handleClickClose}
                    onScheduleChanged={(e: any) => {
                        onTaskDefinitionChange({ id: row.id, name: row.name, state: row.state, schedule: `cron(${e})` }, () => {
                            setScheduleExpression(e);
                            row.schedule = `cron(${e})`;
                            handleClickClose();
                        });
                    }}
                    scheduleExpression={scheduleExpression}
                />
            </div>
        </div>
    );
}

function getCronValue(scheduleExpression: string | undefined): string {
    if (scheduleExpression == undefined) return "0 */2 * * ? *";
    return scheduleExpression.replace("cron(", "").slice(0, -1);
}

function describeScheduleExpression(scheduleExpression: string | undefined) {
    if (scheduleExpression != undefined) {
        return cronstrue.toString(getCronValue(scheduleExpression), { verbose: false });
    } else {
        return "Never";
    }
}

function TaskExecute(props: { row: Task, onTaskExecute: Function }) {
    const StyledButton = withStyles({
        root: {
            marginRight: "5px",
            marginLeft: "5px",
        },
    })(Button);

    const { row, onTaskExecute } = props;
    const [openExecute, setOpenExecute] = React.useState(false);
    const [executing, setExecuting] = React.useState(false);

    let [assetUpdateTaskInput, setAssetUpdateTaskInput] = React.useState({
        input: "",
        fields: {
            OrderProductId: false,
            QuoteLineID: false,
        }
    });

    const handleClickOpenExecute = () => {
        setOpenExecute(true);
    };

    const handleClickCloseExecute = () => {
        setOpenExecute(false);
    };

    function triggerExecution(task: Task) {
        setExecuting(true);
        TaskApi.executeTaskType(task.id)
            .then((_) => {
                setTimeout(function () {
                    Store.dispatch(
                        openSnackbar({
                            message: `Task ${task.name} was executed`,
                            severity: SnackbarSeverity.SUCCESS,
                        })
                    );
                    setOpenExecute(false);
                    setExecuting(false);
                    onTaskExecute();
                }, 2000);
            })
            .catch(() => {
                setExecuting(false);
            });
    }

    function triggerAssetUpdate(task: Task, assetUpdateTaskInput: any) {
        setExecuting(true);
        TaskApi.executeTaskType(task.id, assetUpdateTaskInput)
            .then((_) => {
                setTimeout(function () {
                    Store.dispatch(
                        openSnackbar({
                            message: `Task ${task.name} was executed`,
                            severity: SnackbarSeverity.SUCCESS,
                        })
                    );
                    setOpenExecute(false);
                    setExecuting(false);
                    onTaskExecute();
                }, 2000);
            })
            .catch(() => {
                setExecuting(false);
            });
    }

    const isAssetUpdate = row.name.trim() === ASSET_UPDATE;

    if (isAssetUpdate)
        return (
            <div>
                <Tooltip title="Execute">
                    <IconButton style={{ padding: "1px" }} aria-label="execute" onClick={handleClickOpenExecute}>
                        <PlayCircleFilledSharpIcon />
                    </IconButton>
                </Tooltip>
                <div>
                    <Dialog
                        fullWidth={true}
                        maxWidth={"md"}
                        open={openExecute}
                        onClose={handleClickCloseExecute}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description">
                        <DialogTitle id="alert-dialog-title">Execute Task</DialogTitle>
                        <DialogContent>
                            {

                                (
                                    <>


                                        <Container maxWidth="md">

                                            <DialogContentText>
                                                Enter Sales Orders to Process
                                            </DialogContentText>

                                            <FormGroup>


                                                <TextareaAutosize
                                                    style={{ width: '100%', marginBottom: '10px' }}
                                                    aria-label="sales orders"
                                                    placeholder="Example : SO1023,SO1022,SO1021,SO1020,SO1019,SO1018,SO1017,SO1016,SO1015,SO1014,SO1013,SO1012,SO1011,SO1010"
                                                    defaultValue=""
                                                    rows={5}
                                                    onChange={(e) => {
                                                        setAssetUpdateTaskInput({ ...assetUpdateTaskInput, input: e.target.value })
                                                    }}
                                                />

                                                <FormControlLabel control={<Switch
                                                    checked={assetUpdateTaskInput.fields.OrderProductId}
                                                    color="primary"
                                                    onChange={(e: any, checked: boolean) => {
                                                        setAssetUpdateTaskInput({ ...assetUpdateTaskInput, input: assetUpdateTaskInput.input, fields: { ...assetUpdateTaskInput.fields, OrderProductId: checked } })
                                                    }}
                                                />} label="OrderProductId" />

                                                <FormControlLabel control={<Switch
                                                    checked={assetUpdateTaskInput.fields.QuoteLineID}
                                                    color="primary"
                                                    onChange={(e: any, checked: boolean) => {
                                                        setAssetUpdateTaskInput({ ...assetUpdateTaskInput, input: assetUpdateTaskInput.input, fields: { ...assetUpdateTaskInput.fields, QuoteLineID: checked } })
                                                    }}
                                                />} label="QuoteLineID" />

                                            </FormGroup>

                                        </Container>

                                    </>
                                )

                            }

                        </DialogContent>
                        <br />
                        <DialogActions>
                            <div style={{ justifyContent: "flex-end", display: "flex", marginRight: '10px', marginBottom: '10px' }} >
                                <StyledButton color="secondary" variant="outlined" onClick={handleClickCloseExecute}>
                                    Cancel
                                </StyledButton>

                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <div style={{ position: "relative" }}>
                                        <Button variant="contained"
                                            color="primary"
                                            autoFocus
                                            disabled={executing}
                                            onClick={() => triggerAssetUpdate(row, assetUpdateTaskInput)} >
                                            OK
                                        </Button>
                                        {executing && (
                                            <CircularProgress
                                                size={24}
                                                style={{
                                                    position: "absolute",
                                                    top: "50%",
                                                    left: "50%",
                                                    marginTop: -12,
                                                    marginLeft: -12,
                                                }}
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>
                        </DialogActions>
                    </Dialog>
                </div>
            </div>
        );

    return (
        <div>
            <Tooltip title="Execute">
                <IconButton style={{ padding: "1px" }} aria-label="execute" onClick={handleClickOpenExecute}>
                    <PlayCircleFilledSharpIcon />
                </IconButton>
            </Tooltip>
            <div>
                <Dialog
                    open={openExecute}
                    onClose={handleClickCloseExecute}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description">
                    <DialogTitle id="alert-dialog-title">Execute Task</DialogTitle>
                    <DialogContent>
                        {
                            (<>{`Are you sure you want to execute task `} <b>{`${row.name}`}</b>?</>)

                        }

                    </DialogContent>
                    <br />
                    <DialogActions>
                        <div style={{ justifyContent: "flex-end", display: "flex", }} >
                            <StyledButton color="secondary" variant="outlined" onClick={handleClickCloseExecute}>
                                Cancel
                            </StyledButton>

                            <div style={{ display: "flex", alignItems: "center" }}>
                                <div style={{ position: "relative" }}>
                                    <Button variant="contained"
                                        color="primary"
                                        autoFocus
                                        disabled={executing}
                                        onClick={() => triggerExecution(row)} >
                                        OK
                                    </Button>
                                    {executing && (
                                        <CircularProgress
                                            size={24}
                                            style={{
                                                position: "absolute",
                                                top: "50%",
                                                left: "50%",
                                                marginTop: -12,
                                                marginLeft: -12,
                                            }}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                    </DialogActions>
                </Dialog>
            </div>
        </div>
    );

}



export default function TasksTable(props: {
    isLoading: boolean,
    isUpdating: boolean,
    onTaskDefinitionChange: Function,
    onTaskExecute: Function,
    rows: Task[],
}) {
    const { isLoading, isUpdating, onTaskDefinitionChange, onTaskExecute, rows } = props;

    const columns: Column<Task>[] = [
        { title: "Name", field: "name", cellStyle: { textAlign: 'center' } },
        { title: "Last Execution", field: "lastExecution", cellStyle: { width: "25%", textAlign: 'center' }, render: (row) => <LastExecution row={row} /> },
        {
            title: "Schedule",
            field: "schedule",
            cellStyle: { width: "40%", textAlign: 'center' },
            render: (row) => <TaskSchedule isUpdating={isUpdating} row={row} onTaskDefinitionChange={onTaskDefinitionChange} />,
        },
        {
            title: "Enabled",
            field: "state",
            cellStyle: { textAlign: 'center', width: "5%" },
            render: (row) => <TaskEnabled row={row} onTaskDefinitionChange={onTaskDefinitionChange} />,
        },
        { title: "Actions", cellStyle: { textAlign: 'center', width: "5%" }, render: (row) => <TaskExecute row={row} onTaskExecute={onTaskExecute} /> },
    ];

    return (
        <Paper>
            <MaterialTable
                columns={columns}
                data={rows}
                isLoading={isLoading}
                components={{
                    Toolbar: () => null,
                }}
                options={{
                    showTitle: false,
                    sorting: false,
                    search: false,
                    paging: false,
                    headerStyle: {
                        textAlign: "center",
                        fontWeight: "bolder",
                        zIndex: 0,
                    },
                    rowStyle: {
                        zIndex: -1,
                        textAlign: 'center',
                    }
                }}
            />
        </Paper>
    );
}
