import {
    Box,
    MenuItem,
    Paper,
    Select,
    withStyles,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';

import { UserRoleType, UserType } from '../../services/User';
import { SnackbarSeverity } from '../../components/SnackbarData';
import { openSnackbar, Store } from '../../common/Store';
import UserApi from '../../services/UserApi';
import moment from 'moment';
import MaterialTable, { Column } from 'material-table';
import ColoredLine from '../../components/ColoredLine';

const StyledSelect = withStyles({
    outlined: {
        paddingTop: '12px',
        paddingBottom: '12px',
        fontSize: '0.875rem',
    },
})(Select);

function formatDate(date: string) {
    if (date) {
        return moment(date, 'YYYY-MM-DD[T]HH:mm:ss.SSS[Z]')
            .format('M/D/YYYY h:mm a');
    }
    return 'Never';
}

export function UserManagement() {
    const [users, setUsers] = useState<UserType[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [editingUserRole, setEditingUserRole] = useState<UserRoleType | null>();


    const columns:  Column<UserType>[] = [
        { title: 'Name', field: 'username', editable: 'never' },
        {
            title: 'Role', field: 'role', filtering: false, editComponent: props => (
                <div style={{ minWidth: 150, maxWidth: 150 }}>
                    <StyledSelect
                        variant='outlined'
                        defaultValue={props.value}
                        onChange={(event: any) => {
                            setEditingUserRole(event.target.value);
                        }}
                    >
                        <MenuItem value={'Biz'}>Biz</MenuItem>
                        <MenuItem value={'Admin'}>Admin</MenuItem>
                    </StyledSelect>
                </div>
            ),
        },
        {
            title: 'Created on',
            field: 'createdOn',
            filtering: false,
            editable: 'never',
            render: rowData => formatDate(rowData.createdOn),
        },
        {
            title: 'Updated on',
            field: 'updatedOn',
            filtering: false,
            editable: 'never',
            render: rowData => formatDate(rowData.updatedOn),
        },
        { title: 'Updated By', field: 'updatedBy', filtering: false, editable: 'never' },
    ];

    useEffect(() => {
        getUsers();
    }, []);

    const getUsers = () => {
        setLoading(true);
        UserApi.getUsers().then(users => {
            setUsers(users);
        }).finally(() => {
            setLoading(false);
        });
    };

    const saveUser = async (user: UserType): Promise<void> => {
        if (!editingUserRole || !users) return;

        await UserApi.updateUser(user.username, editingUserRole);

        getUsers();

        setEditingUserRole(null);

        Store.dispatch(
            openSnackbar({
                message: `User role updated successfully`,
                severity: SnackbarSeverity.SUCCESS,
            }),
        );
    };

    return (
        <Box>
            <Box mb={'18px'}>
                <h1>User Management</h1>
            </Box>
            <Box>
                <div>
                    <ColoredLine/>
                    <Paper>
                        <MaterialTable
                            columns={columns}
                            data={users}
                            editable={{
                                onRowUpdate: (newData, oldData) =>
                                    saveUser(newData),
                            }}
                            isLoading={loading}
                            options={{
                                showTitle: false,
                                searchFieldAlignment: "left",
                                actionsColumnIndex: -1,
                                emptyRowsWhenPaging: false,
                                headerStyle: {
                                    textAlign: 'left',
                                    fontWeight: 'bolder',
                                },
                                actionsCellStyle: {
                                    textAlign: 'left',
                                    paddingRight: '24px'

                                },
                            }}
                        />
                    </Paper>
                </div>
            </Box>
        </Box>
    );
}
