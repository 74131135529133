import React, { useContext, useEffect, useState } from 'react';
import { AppBar, IconButton, LinearProgress, Menu, MenuItem, Paper, Toolbar, Typography } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import UserInfoContext from './UserInfoContext';
import { UserRoleType } from '../services/User';
import ShowIf from './ShowIf';
import { UserMenu } from './UserMenu';
import { useAuth0 } from '@auth0/auth0-react';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    textAlign: 'center',
    fontWeight: 'bold',
  },
  titleDiv: {
    position: 'absolute',
    width: '100%',
    height: '48px',
    display: 'flex',
    alignItems: 'center',
  },
  menuItem: {
    color: theme.palette.text.primary,
  },
  appBar: {
    height: '48px',
    '& .MuiToolbar-regular': {
      minHeight: '48px',
    },
  },
}));

export default () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const { isLoading, isAuthenticated } = useAuth0();
  const [isPageLoading, setIsPageLoading] = useState(true);
  const userInfo = useContext(UserInfoContext);

  useEffect(() => {
    if (!isLoading) {
      setIsPageLoading(false);
    } else {
      setIsPageLoading(true);
    }
  }, [isLoading]);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const classes = useStyles();
  return (
    <React.Fragment>
      {!isPageLoading ? (
        <AppBar position='static' className={classes.appBar}>
          <div className={classes.titleDiv}>
            <Typography variant='h5' className={classes.title}>
              SSOT (IB / Contracts)
            </Typography>
          </div>
          <Toolbar style={{ justifyContent: 'space-between' }}>
            {isAuthenticated && (
              <div>
                <div>
                  <IconButton
                    aria-controls='simple-menu'
                    aria-haspopup='true'
                    onClick={handleClick}
                    edge='start'
                    className={classes.menuButton}
                    color='inherit'
                    aria-label='menu'
                  >
                    <MenuIcon />
                  </IconButton>
                  <Menu id='simple-menu' anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
                    <Link className={classes.menuItem} to='/dashboard' style={{ textDecoration: 'none' }}>
                      <MenuItem onClick={handleClose}>Dashboard</MenuItem>
                    </Link>
                    <Link className={classes.menuItem} to='/executions' style={{ textDecoration: 'none' }}>
                      <MenuItem onClick={handleClose}>Executions</MenuItem>
                    </Link>
                    <Link className={classes.menuItem} to='/tasks' style={{ textDecoration: 'none' }}>
                      <MenuItem onClick={handleClose}>Tasks</MenuItem>
                    </Link>
                    <ShowIf condition={userInfo.role == UserRoleType.Admin}>
                      {/* <Link className={classes.menuItem} to="/configurations" style={{ textDecoration: "none" }}>
												<MenuItem onClick={handleClose}>Configurations</MenuItem>
											</Link> */}
                      <Link className={classes.menuItem} to='/users' style={{ textDecoration: 'none' }}>
                        <MenuItem onClick={handleClose}>Users</MenuItem>
                      </Link>
                    </ShowIf>
                  </Menu>
                </div>
              </div>
            )}
            {isAuthenticated && <UserMenu userName={userInfo.username}></UserMenu>}
          </Toolbar>
        </AppBar>
      ) : (
        <Paper>
          <LinearProgress />
        </Paper>
      )}
    </React.Fragment>
  );
};
